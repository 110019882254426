import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {useEffect, useState} from "react";
import {DiagramProps} from "./useGetDiagramType";
import {Box} from "@mui/material";
import {TimeSeriesEntry} from "../../types/type";
import {NodeColorMap, transformToRechartsSeries} from "./RechartsHelper";
import {CustomTooltip} from "./CustomTooltip";

export const KapionLineChart = (props: DiagramProps) => {


    const [timeSeries, setTimeSeries] =useState<TimeSeriesEntry[]>([])
    const [nodes, setNodes] = useState<string[]>([])
    const [colorMap, setColorMap] = useState<NodeColorMap>({})

    useEffect(() => {

        const [series,nodes, colorPalette] = transformToRechartsSeries(props);
        setTimeSeries(series)
        setNodes(nodes)
        setColorMap(colorPalette)

    }, [props.data]);


    return  <Box display={"flex"}  flexDirection={"column"} position={"relative"}  width={"100%"} height={"100%"}>

                     <ResponsiveContainer width={"100%"} height={`${100}%`}>
                    <LineChart  width={1000} height={1000} data={timeSeries}>

                        {
                            props.kpis.map((kpi, index) => {
                                const orientation = (index % 2 === 0 ? "left" : "right")
                                const angleTilt = (index % 2 === 0 ? -90 : 90)
                                const YAxisPosition = (index % 2 === 0 ? "insideLeft" : "insideRight")

                                return [
                                    nodes.map(node => {
                                        const nodeKey = `${node}-${kpi}`

                                        console.log("dataKey", `${node}-${kpi}-`, colorMap[nodeKey], timeSeries)
                                        return <Line key={nodeKey} yAxisId={kpi} dot={false} type="monotone"
                                                     dataKey={nodeKey}
                                                     stroke={colorMap[nodeKey]}
                                                     strokeWidth={2}
                                        />
                                    }).flat()

                                ,

                                    <YAxis width={100}  label={{ value: kpi, angle: angleTilt, position: YAxisPosition}}  type={"number"} key={index} yAxisId={kpi}  orientation={orientation}/>
                                    ]
                            })
                        }

                        {/*@ts-ignore weil recharts richtig dumme falsche type definitions hat.....*/ }
                        <Tooltip content={<CustomTooltip />}/>

                        <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>

                        <XAxis type={"category"} fontSize={"10"} dataKey="time" angle={-45} textAnchor={"end"} height={85} />

                    </LineChart>
                </ResponsiveContainer>

</Box>}