import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { addReducer, RootState } from "../../../store";

export const useUIServices = () => {
  const [serviceMap, setServiceMap] = useState<Map<string, any>>(
    new Map<string, any>()
  );
  const uiDefinitions = useSelector(
    (state: RootState) => state.uiDefinitions.uiDefinitions
  );

  useEffect(() => {
    let newServiceMap = new Map<string, any>();
    for (let definition of uiDefinitions) {
      const uiServiceSet = addReducer(definition);
      newServiceMap.set(definition.model, uiServiceSet);
    }
    setServiceMap(newServiceMap);
  }, [uiDefinitions]);

  return serviceMap;
};
