import React from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";

import { GenericActionBar, GenericActionBarAction } from "../../../MaterialOnFire/GenericUIFields/GenericActionBar";
import { Delete, Save } from "@mui/icons-material";
import { HardCodedInstructionUI } from "./HardCodedInstructionUI";
import { DynamicInstructionUi } from "./DynamicInstructionUi";

export const OnboardingTemplate = (props: any) => {
  const handleSave = () => {
    // Add save logic here
  };

  const handleDelete = () => {
    props.updateTemplateCallback([]);
  };

  // Define actions for the GenericActionBar
  const actions: GenericActionBarAction[] = [
    {
      name: "Save",
      icon: <Save />,
      action: handleSave,
    },
    {
      name: "Delete",
      icon: <Delete />,
      action: handleDelete,
    },
  ];

  return (
    <Box
      key="Template-Container"
      width={"100%"}
      height={"100%"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      component={Paper}
      display={"flex"}
      flexDirection={"column"}
      gap={"0.25rem"}
    >
      {/* Generic Action Bar */}
      <GenericActionBar state={props.template} actions={actions} />

      {/* Add Instruction Button */}
      <Button
        onClick={() => {
          props.updateTemplateCallback([
            ...props.template,
            {
              type: "hardcoded",
              stepName: "Step Name",
              payload: {
                bytes: [],
                fPort: 1,
              },
            },
          ]);
        }}
      >
        Add Instruction
      </Button>

      {/* Instructions List */}
      {props.template.map((instruction:any , index:any) => {
        return (
          <Box key={`row-${index}`} display={"flex"} width={"100%"} gap={"0.25rem"}>
            <TextField
              key={`stepName-${index}`}
              label={"Step Name"}
              value={instruction.stepName}
              disabled
            />
            <Box width={"10rem"}>
              <FormControl key={`typeSelect-${index}`} fullWidth>
                <InputLabel id={`typeSelectLabel-${index}`}>Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={instruction.type}
                  label="Type"
                  onChange={(event) => {
                    //@ts-ignore
                    props.updateTemplateCallback([
                      ...props.template.slice(0, index),
                      { ...instruction, type: event.target.value },
                      ...props.template.slice(index + 1),
                    ]);
                  }}
                >
                  <MenuItem value={"hardcoded"}>Hardcoded</MenuItem>
                  <MenuItem value={"dynamic"}>Dynamic</MenuItem>
                </Select>
              </FormControl>
            </Box>

            {
                    instruction.type === "hardcoded" ? <HardCodedInstructionUI instruction={instruction} updateInstruction={(newInstruction) => {
                        props.updateTemplateCallback([...props.template.slice(0, index), newInstruction, ...props.template.slice(index+1)])}} />: <DynamicInstructionUi instruction={instruction} updateInstruction={(newInstruction) => {
                        props.updateTemplateCallback([...props.template.slice(0, index), newInstruction, ...props.template.slice(index+1)])
                    }}/>
                }
          </Box>
        );
      })}
    </Box>
  );
};

