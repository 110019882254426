import { useMemo, useState, useEffect } from "react";
import { HardcodedPayload, isHardcodedPayload } from "./OnboardingTemplateTypes";
import { Box, TextField } from "@mui/material";
import { HexMask } from "../createNewLoraDeviceWizzard/masks/HexMask";

export const HardCodedInstructionUI = (props: { instruction: any, updateInstruction: (newInstruction: any) => void }) => {
    const localPayload = useMemo<HardcodedPayload>(() => {
        return isHardcodedPayload(props.instruction.payload)
            ? props.instruction.payload
            : { payload: { bytes: [], fPort: 1 } };
    }, [props.instruction]);

    const [bytesValue, setBytesValue] = useState(
        localPayload.bytes.length > 0? localPayload.bytes.join(" ") : "");

    useEffect(() => {
        setBytesValue(localPayload.bytes.length > 0 ? localPayload.bytes.join(" ") : "");
    }, [localPayload]);

    const handleBytesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBytesValue(event.target.value.toUpperCase());
    };

    const handleBytesAccept = (value: string) => {
        props.updateInstruction({
            ...props.instruction,
            payload: {
                ...localPayload,
                bytes: value.trim().split(" "),
            },
        });
    };

    return (
        <Box gap="0.25rem" display="flex" flexGrow={1}>
            <TextField
                type="number"
                key="fPort"
                label="FPort"
                required
                value={localPayload.fPort}
                onChange={(event) => {
                    props.updateInstruction({
                        ...props.instruction,
                        payload: { ...localPayload, fPort: parseInt(event.target.value) },
                    });
                }}
            />
            <TextField
                key="bytes"
                label="Bytes"
                fullWidth
                required
                value={bytesValue}
                onChange={handleBytesChange}
                slotProps={{
                    input: {
                        inputComponent: HexMask as any,
                        inputProps: {
                            onAccept: handleBytesAccept,
                        },
                    }
                   
                }}
            />
        </Box>
    );
};