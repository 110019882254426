import {useEffect, useState} from "react";

import { getValue } from "firebase/remote-config";
import {getRemoteConfigInstance, initFireBaseApp, remoteConfig} from "../firebase-config";
import {getApps} from "firebase/app";
import {firebaseConfig} from "../../../firebaseConfig";
import {use} from "i18next";


export const useGetFirebaseConfigParameter = <T>(parameter: string   ): T|null => {


    const [appInitialized, setAppInitialized] = useState(false)
    useEffect(() => {
           initFireBaseApp(firebaseConfig)
           getRemoteConfigInstance().then( () => { setAppInitialized(true)})
    }, [])

    const [result, setResult] = useState<T|null>(null)

    useEffect(() => {
        if (appInitialized) {
            const config = getValue(remoteConfig,parameter)
            let temp:T= {} as unknown as T;
            if( config === undefined  ){
                setResult(null)
            }

            switch ( typeof temp){
                case "boolean": setResult( config.asBoolean() as unknown as T ); break
                case "string": setResult( config.asString() as unknown as T ); break
                case "number": setResult( config.asNumber() as unknown as T );break
                case "object":  setResult( config.asString() ?           ( JSON.parse(config.asString()) as unknown as T) : null);
            }
        }
    }, [appInitialized,parameter]);


    return result
}