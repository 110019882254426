import {useEffect, useRef, useState} from "react";
import {LoraNode} from "../../model/lora-node/LoraNode";
import {useSelector} from "react-redux";
import {HardwareType} from "../../model/hardware-type/hardwareType";
import {
    fetchLastDataFromBackend,
    fetchTimeSeriesFromBackend
} from "../custom-hooks/uiEnhancers/Dashboards/DiagramHelper/useGetDiagramData";
import {
    BACKEND_CONFIG_PARAMETER_NAME,
    backendRemoteConfig
} from "../MaterialOnFire/custom-hooks/types/firebaseRemoteConfigTypes";
import {useGetFirebaseConfigParameter} from "../MaterialOnFire/custom-hooks/useGetFirebaseConfigParameter";
import {DiagramProperties, TimeSeriesEntry} from "../custom-hooks/uiEnhancers/Dashboards/types/type";


export interface MinKPIMapping {
    name: string,
    hardwareType: HardwareType
    mappings: {
        name: string,
        displayOnOverview: boolean,
        unitFrom: string,
    }[]

}

interface LastRecords {
    [key: string]: {
        value: number | string,
        unit: string,
        timestamp: string
    }
}

export const useGetDeviceTimeSeries = (nodeId?: string): [boolean, TimeSeriesEntry[], LastRecords | null] => {


    const [loading, setLoading] = useState(true);
    const [timeSeriesData, setTimeSeriesData] = useState<TimeSeriesEntry[]>([])
    const [lastData, setLastData] = useState<LastRecords | null>(null)

    const loraNode = useSelector((state: any) => state["loraNodes"]?.items as LoraNode  []).find(node => node.id === nodeId)
    const kpiMappings = useSelector((state: any) => state["KPIMapping"]?.items as MinKPIMapping  [])
    const backendConfig = useGetFirebaseConfigParameter<backendRemoteConfig>(BACKEND_CONFIG_PARAMETER_NAME)
    const hardwareType = useSelector((state: any) => (state["hardwareTypes"]?.items as HardwareType[]).find(hw => hw.id === loraNode?.hardwareType?.id))


    const nodeRef = useRef(nodeId)
    useEffect(() => {


        setLoading(true)
        nodeRef.current = nodeId;

        const loadData = async (localNodeId: string, kpiMappingName: string) => {


            try {
                if (backendConfig && hardwareType) {

                    const diagramProps: DiagramProperties = {
                        name: "Diagram",
                        diagramProperties: {
                            duration: 168,
                            durationUnit: "h",
                            startTime: "now()",
                            diagramType: "line",
                            aggregationInterval: "1h",
                            aggregationStrategy: "mean",
                        },
                        id: "1",
                        influxQueryProperties: {
                            nodeIds: [nodeId || ""],
                            values: [".*"],
                            influxBucketName: hardwareType.id || ""
                        }
                    }

                    const [result, result2] = await Promise.all([fetchTimeSeriesFromBackend(backendConfig, diagramProps, hardwareType.name),
                        fetchLastDataFromBackend(backendConfig, diagramProps, hardwareType.name)])

                    if (localNodeId === nodeRef.current) {
                        setTimeSeriesData(result.timeSeries)

                        if(result2.timeSeries.length > 0){
                            setLastData(result2.timeSeries.map(value => {

                                const lastRecord: LastRecords = {}
                                Object.keys(value).forEach(key => {
                                    if (!["nodeId", "key", "time"].includes(key)) {
                                        lastRecord[key] = {
                                            value: value[key],
                                            unit: "", //todo include into influxdb
                                            timestamp: new Date(value.time).toLocaleString()
                                        }
                                    }

                                })
                                return lastRecord
                            }).reduce((acc, val) => {
                                return {...acc, ...val}
                            }))
                        }else{
                            setLastData(null);
                        }


                        setLoading(false)
                    }
                }
            } catch (e) {

                console.error(e)

            }

        }


        if (loraNode && loraNode.hardwareType?.name) {
            loadData(loraNode.id, loraNode.hardwareType.name).then(() => {
            })
        }


    }, [nodeId, kpiMappings, backendConfig, hardwareType, loraNode]);

    return [loading as boolean, timeSeriesData, lastData]

}