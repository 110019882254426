import { getLoraNodeActionsFromHardWareType } from "./MaintainLoraNodeActions";
import { LoraNode } from "../../../model/lora-node/LoraNode";
import { GenericActionBarAction } from "../../MaterialOnFire/GenericUIFields/GenericActionBar";
import { Group, GroupState } from "../../../model/groups/groupState";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { HardwareType } from "../../../model/hardware-type/hardwareType";
import { useSnackbar } from "notistack";
import { useTranslation } from "../../MaterialOnFire/custom-hooks/useTranslation";
import { UiEnhancements } from "../../MaterialOnFire/custom-hooks/useGetRoutes";
import { getRegisterABPDeviceFunction } from "./createNewLoraDeviceWizzard/createNewLoraNodeWizardSteps/FinishStep";
import { getFunctions, httpsCallable } from "firebase/functions";
import { fireBaseApp } from "../../MaterialOnFire/firebase-config";

export const useGetGroupUiEnhancer = () => {
  const cachedHardwaretypes = useSelector(
    (
      state: RootState // @ts-ignore
    ) => state["hardwareTypes"]?.items as unknown as HardwareType[]
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const groupActionBarEnhancer = (
    item: Group,
    actions: GenericActionBarAction[]
  ) => {
    const originalSaveAction = { ...actions[0] };

    actions[0] = {
      ...originalSaveAction,
      action: (newState: Group, uiDefinition) => {
        originalSaveAction.action(newState, uiDefinition);
        const registerABPDevice = getRegisterABPDeviceFunction();

        registerABPDevice({
          deviceId: newState.id,
          dev_eui: undefined,
          join_eui: undefined,
          application_root_key: newState.loraSettings.application_session_key,
          dev_addr: newState.loraSettings.dev_addr,
          network_session_key: newState.loraSettings.network_session_key,
          multicast: true,
          lat: 5, //todo richtigen lat
          lng: 7, //todo richtigen lng
          applicationId: newState.loraSettings.application.id,
          hardwareType: newState.hardwareType,
          lora_version: "MAC_V1_0_3",
          lora_phy_version: "PHY_V1_0_3_REV_A",
          frequency_id: "EU_863_870_TTN",
          isGroup: true // this stops the Creation of an addiontal LoraNode
        })
          .then(() => {
            enqueueSnackbar(t("createLoraNodeWizard.finishstep.success"), {
              variant: "success",
            });
          })
          .catch((e) => console.error(e));
      },
    };

    // at position 1 there is the generated delete action
    let deleteAction = actions[1];
    deleteAction.action = (newState: Group, uiDefinition) => {
      const functions = getFunctions(fireBaseApp, "europe-west1");
      const purgeDevice = httpsCallable(functions, "purgeDevice");
      purgeDevice({
        dev_eui: item.id,
        applicationId: newState.loraSettings.application.id,
        deviceId: item.id,
        isGroup: true,
      })
        .then(() => {
          enqueueSnackbar(t("group.purgeSuccessful"), { variant: "success" });
        })
        .catch((e) =>
          enqueueSnackbar(t("loraNode.purgeFailed") + ":" + e.message, {
            variant: "error",
          })
        );
    };

    if (item?.hardwareType) {
      const hardwareInstructions = getLoraNodeActionsFromHardWareType(
        item?.hardwareType,
        cachedHardwaretypes,
        enqueueSnackbar,
        closeSnackbar,
        true
      );
      return [...actions, ...hardwareInstructions];
    }
    return actions;
  };

  const groupEnhancer = {
    enhanceActionBar: groupActionBarEnhancer,
  } as UiEnhancements;
  return { key: "groups", enhancer: groupEnhancer };
};
