import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  TCreateLoraNodeDataModel,
} from "../CreateLoraNodeWizardWrapper";
import { useTranslation } from "../../../../MaterialOnFire/custom-hooks/useTranslation";

import { SixteenHexBitTextMask } from "../masks/SixteenHexBitMask";
import { ThirtyTwoHexBitMask } from "../masks/ThirtyTwoHexBitMask";
import { useState } from "react";

import { CameraAlt } from "@mui/icons-material";
import { QRCodeReader } from "../../qrCodeReader/QRCodeReader";
import { EightHexBitTextMask } from "../masks/EightHexBitMask";

export const ProvideDeviceInformationStep = (props: {
  newLoraNode: TCreateLoraNodeDataModel;
  updateNewLoraNode: (newState: TCreateLoraNodeDataModel) => void;
}) => {
  const { t } = useTranslation();

  const [qrScannerOpen, setQRScannerOpen] = useState(false);
  const deviceType = ["OTAA", "ABP"];

  return (
    <Grid container spacing={2} sx={{ p: 1 }}>
      <Grid size={{xs:12}}>
        <IconButton color={"secondary"} onClick={() => setQRScannerOpen(true)}>
          {" "}
          <CameraAlt />
        </IconButton>
      </Grid>
      <QRCodeReader
        open={qrScannerOpen}
        onClose={() => {
          setQRScannerOpen(false);
          debugger;
        }}
        onComplete={(data) => {
          setQRScannerOpen(false);
          let parsedData = data;
          props.updateNewLoraNode({
            ...props.newLoraNode,
            dev_eui: parsedData?.dev_eui || "",
            deviceId: parsedData?.deviceId || "",
            application_root_key: parsedData?.application_root_key || "",
            network_session_key: parsedData?.network_session_key || "",
            activationType: parsedData?.activationType || "OTAA",
            join_eui: parsedData?.join_eui || "",
          });
        }}
      />

      <Grid container size={{xs:6}} spacing={2}>
        <FormControl fullWidth required={true}>
          <InputLabel>
            {t(
              "createLoraNodeWizard.provideDeviceInformationStep.activationType"
            )}
          </InputLabel>
          <Select
            required={true}
            fullWidth
            label={t(
              "createLoraNodeWizard.provideDeviceInformationStep.activationType"
            )}
            value={props.newLoraNode?.activationType || ""}
            onChange={(e) => {
              props.updateNewLoraNode({
                ...props.newLoraNode,
                activationType: e.target.value,
              });
            }}
          >
            {deviceType.map((type) => {
              return (
                <MenuItem
                    key={`menuItem-${type}`}
                  value={type}
                  onClick={() =>
                    props.updateNewLoraNode({
                      ...props.newLoraNode,
                      activationType: type,
                    })
                  }
                >
                  {type}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid container  size={{ xs: 6 }} spacing={2}>
        {props.newLoraNode.activationType === "ABP" && (
          <FormControlLabel
            labelPlacement="start"
            control={
              <Checkbox
                checked={props.newLoraNode.multicast}
                onChange={(event) =>
                  props.updateNewLoraNode({
                    ...props.newLoraNode,
                    multicast: event.target.checked,
                  })
                }
              ></Checkbox>
            }
            label={t(
              "createLoraNodeWizard.provideDeviceInformationStep.multicast"
            )}
          />
        )}
      </Grid>

      <Grid container size={{ xs: 6 }} spacing={2}>
        <TextField
          required={true}
          fullWidth
          label={t(
            "createLoraNodeWizard.provideDeviceInformationStep.deviceId"
          )}
          value={props.newLoraNode?.deviceId || ""}
          onChange={(e) => {
            props.updateNewLoraNode({
              ...props.newLoraNode,
              deviceId: e.target.value.toLowerCase(),
            });
          }}
        />
      </Grid>
      <Grid size={{ xs: 6 }}>
        {props.newLoraNode.activationType === "ABP" && (
          <TextField
            fullWidth
            required={true}
            name={"dev_addr"}
            label={t(
              "createLoraNodeWizard.provideDeviceInformationStep.dev_addr"
            )}
            value={props.newLoraNode?.dev_addr || ""}
            onChange={(e) => {
              props.updateNewLoraNode({
                ...props.newLoraNode,
                dev_addr: e.target.value,
              });
            }}
            InputProps={{
              inputComponent: EightHexBitTextMask as any,
            }}
          />
        )}
      </Grid>

      {((props.newLoraNode.activationType === "ABP" &&
        props.newLoraNode.multicast === false) ||
        props.newLoraNode.activationType === "OTAA") && (
        <Grid size={{ xs: 6 }}>
          <TextField
            fullWidth
            required={true}
            name={"dev_eui"}
            label={t(
              "createLoraNodeWizard.provideDeviceInformationStep.dev_eui"
            )}
            value={props.newLoraNode?.dev_eui || ""}
            onChange={(e) => {
              props.updateNewLoraNode({
                ...props.newLoraNode,
                dev_eui: e.target.value.toUpperCase(),
              });
            }}
            InputProps={{
              inputComponent: SixteenHexBitTextMask as any,
            }}
          />
        </Grid>
      )}
      {props.newLoraNode.activationType === "OTAA" && (
        <Grid size={{ xs: 6 }}>
          <TextField
            fullWidth
            required={true}
            label={t(
              "createLoraNodeWizard.provideDeviceInformationStep.join_eui"
            )}
            value={props.newLoraNode?.join_eui || ""}
            onChange={(e) => {
              props.updateNewLoraNode({
                ...props.newLoraNode,
                join_eui: e.target.value.toUpperCase(),
              });
            }}
            InputProps={{
              inputComponent: SixteenHexBitTextMask as any,
            }}
          />
        </Grid>
      )}
      <Grid size={{ xs: 12 }}>
        <TextField
          fullWidth
          required={props.newLoraNode.activationType === "ABP"}
          label={t(
            "createLoraNodeWizard.provideDeviceInformationStep.application_root_key"
          )}
          value={props.newLoraNode?.application_root_key || ""}
          onChange={(e) => {
            props.updateNewLoraNode({
              ...props.newLoraNode,
              application_root_key: e.target.value.toUpperCase(),
            });
          }}
          InputProps={{
            inputComponent: ThirtyTwoHexBitMask as any,
          }}
        />
      </Grid>

      {props.newLoraNode.activationType === "ABP" && (
        <Grid size={{ xs: 12 }}>
          <TextField
            fullWidth
            required={props.newLoraNode.activationType === "ABP"}
            label={t(
              "createLoraNodeWizard.provideDeviceInformationStep.network_session_key"
            )}
            value={props.newLoraNode?.network_session_key || ""}
            onChange={(e) => {
              props.updateNewLoraNode({
                ...props.newLoraNode,
                network_session_key: e.target.value.toUpperCase(),
              });
            }}
            InputProps={{
              inputComponent: ThirtyTwoHexBitMask as any,
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};
