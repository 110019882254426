export const ColorPalette = {
    color0: {
        stroke: "#00a693",
        backgroundColor: "#00a693",
        color: "#00a693",
        fill: "#00A693",
    },

    color1: {
        stroke: "#3dba8f",
        color: "#3dba8f"
    },
    color2: {
        color: "#69cc88"
    },

    color3: {
        color: "#96dd7f",
        stroke: "#96dd7f",
    },

    color4: {
        color: "#c6ec76"
    },
    color5: {
        color: "#f9f871"
    }
}