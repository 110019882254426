import {DiagramProperties, isTimeseriesData, TimeSeriesData} from "../types/type";
import {useEffect, useState} from "react";
import {useGetFirebaseConfigParameter} from "../../../../MaterialOnFire/custom-hooks/useGetFirebaseConfigParameter";
import {
    BACKEND_CONFIG_PARAMETER_NAME,
    backendRemoteConfig
} from "../../../../MaterialOnFire/custom-hooks/types/firebaseRemoteConfigTypes";
import axios from "axios";
import {useSelector} from "react-redux";
import {HardwareType} from "../../../../../model/hardware-type/hardwareType";
import {LoraNode} from "../../../../../model/lora-node/LoraNode";
import {getAuth} from "firebase/auth";
import {getFullUrlFromBackendConfig} from "./getFullUrlFromBackendConfig";


export const fetchTimeSeriesFromBackend = async (backendConfig: backendRemoteConfig, diagramProperties: DiagramProperties, hardwareName:string): Promise<TimeSeriesData> => {


    const queryBody = {

        "start":  "-" + diagramProperties.diagramProperties.duration + diagramProperties.diagramProperties.durationUnit ,
        "stop":  "now()",
        "measurement": hardwareName,
        "nodeIds":  ( diagramProperties.influxQueryProperties?.nodeIds && diagramProperties.influxQueryProperties?.nodeIds?.length > 0 ) ? diagramProperties.influxQueryProperties.nodeIds : [".*"],
        "kpis": diagramProperties.influxQueryProperties?.values || [".*"],
        "aggregationWindow": diagramProperties.diagramProperties.aggregationInterval,
        "aggregationStrategy": diagramProperties.diagramProperties.aggregationStrategy,

    }

    //get firebase auth token from current user into variable token
     const token =     await    getAuth().currentUser?.getIdToken() || ""
    
    try {
        const requestData = await axios.post(getFullUrlFromBackendConfig(backendConfig, diagramProperties.diagramProperties.diagramType), queryBody ,
            {
                headers: {
                    "Authorization": token
                }
            }
        )
        if (!isTimeseriesData(requestData.data)) {
            throw new Error("Data from Backend not in expected format", requestData.data)
        }
        return requestData.data
    } catch (e) {
        console.error("Error fetching data from backend", e)
        throw e

    }

}

export const fetchLastDataFromBackend = async (backendConfig: backendRemoteConfig, diagramProperties: DiagramProperties, hardwareName:string, onlyStrings=false)  => {
    const queryBody = {

        "start":  "-" + diagramProperties.diagramProperties.duration + diagramProperties.diagramProperties.durationUnit ,
        "stop":  "now()",
        "measurement": hardwareName,
        "nodeIds":  ( diagramProperties.influxQueryProperties?.nodeIds && diagramProperties.influxQueryProperties?.nodeIds?.length > 0 ) ? diagramProperties.influxQueryProperties.nodeIds : [".*"],
        "kpis": diagramProperties.influxQueryProperties?.values || [".*"],
        "onlyStrings":onlyStrings
    }

    //get firebase auth token from current user into variable token
    const token =     await    getAuth().currentUser?.getIdToken() || ""

    try {
        const requestData = await axios.post(getFullUrlFromBackendConfig(backendConfig, "last"), queryBody ,
            {
                headers: {
                    "Authorization": token
                }
            }
        )
        if (!isTimeseriesData(requestData.data)) {
            throw new Error("Data from Backend not in expected format", requestData.data)
        }
        return requestData.data
    } catch (e) {
        console.error("Error fetching data from backend", e)
        throw e

    }


}

export const useGetDiagramData = ( diagramProperties : DiagramProperties)=> {


    const [data, setData] = useState<TimeSeriesData>({timeSeries:[]});
    const [fetchState, setFetchState] = useState<|"loading"|"error"|"success">("loading")
    const backendConfig = useGetFirebaseConfigParameter<backendRemoteConfig>(BACKEND_CONFIG_PARAMETER_NAME)

    //Redux State actions
    const loraNodes = useSelector((state: any) => state["loraNodes"]?.items as LoraNode[])
    const hardwareType = useSelector((state: any) => (state["hardwareTypes"]?.items as HardwareType[]).find( hw => hw.id === diagramProperties?.influxQueryProperties?.influxBucketName))

    useEffect(() => {

        setFetchState("loading")
            if(!diagramProperties?.diagramProperties?.diagramType){
                return
            }
            switch (diagramProperties.diagramProperties.diagramType) {

                case "line":
                    case "bar":

                    if(backendConfig && hardwareType){
                    fetchTimeSeriesFromBackend(backendConfig, diagramProperties, hardwareType?.name).then(data => {
                        const newReplacedTimeSeries = data.timeSeries.map( ts => {
                            const newTs = {...ts}
                            const label = loraNodes.find( node => node.id === ts.nodeId)?.name
                            newTs.originalNodeId = ts.nodeId
                            newTs.nodeId= label || ts.nodeId // todo refactor this to name property and adjust follow up logic

                            return newTs
                        })

                        setData({timeSeries: newReplacedTimeSeries})
                        setFetchState("success")
                    }).catch( e => {
                        console.error("Error fetching diagram data", e)
                        setFetchState("error")
                    })}
                    break;
                case "pie":
                        if(backendConfig && hardwareType){
                            fetchTimeSeriesFromBackend(backendConfig, diagramProperties, hardwareType?.name).then(data => {
                                const newReplacedTimeSeries = data.timeSeries.map( ts => {
                                    const newTs = {...ts}
                                    const label = loraNodes.find( node => node.id === ts.nodeId)?.name
                                    newTs.originalNodeId = ts.nodeId
                                    newTs.nodeId= label || ts.nodeId // todo refactor this to name property and adjust follow up logic

                                    return newTs
                                })

                                setData({timeSeries: newReplacedTimeSeries})
                                setFetchState("success")
                            }).catch( e => {
                                console.error("Error fetching diagram data", e)
                                setFetchState("error")
                            })}


                    break;
                case "kpi":
                    if(backendConfig && hardwareType){
                        fetchTimeSeriesFromBackend(backendConfig, diagramProperties, hardwareType?.name).then(data => {
                            const newReplacedTimeSeries = data.timeSeries.map( ts => {
                                const newTs = {...ts}
                                const label = loraNodes.find( node => node.id === ts.nodeId)?.name
                                newTs.originalNodeId = ts.nodeId
                                newTs.nodeId= label || ts.nodeId // todo refactor this to name property and adjust follow up logic

                                return newTs
                            })

                            setData({timeSeries: newReplacedTimeSeries})
                            setFetchState("success")
                        }).catch( e => {
                            console.error("Error fetching diagram data", e)
                            setFetchState("error")
                        })}

            }

    }, [diagramProperties, backendConfig, hardwareType, loraNodes]);
    return {fetchState, data}
}