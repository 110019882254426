import { ReactNode, useEffect, useState } from "react";
import {
  GenericActionBar,
  GenericActionBarAction,
} from "../../GenericUIFields/GenericActionBar";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Skeleton,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

interface GenericNestedListInterface {
  list: any[];
  isSelected: (item: any) => boolean;
  listItemOnClick: (item: any) => (event: any) => void;
  getListItemVisualizer: (item: any) => ReactNode;
  getListItemActions: (listItem: any) => GenericActionBarAction[];
  getNestingCriteriaFromListItem: (listItem: any) => string;
  getNestingCriteriaVisualizer: (listItem: any) => ReactNode;
  getNestingCriteriaActions: (listItem: any) => GenericActionBarAction[];
}

export const GenericNestedList = (props: GenericNestedListInterface) => {
  const [nestedMap, setNestedMap] = useState<Map<string, any[]>>(new Map());
  const [openMap, setOpenMap] = useState<{ [key: string]: boolean }>({});
  const [isLoading, setIsLoading] = useState(true); // ✅ Added loading state

  const toggleOpenState = (key: string) => {
    setOpenMap((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  useEffect(() => {
    if (!props.list.length) return;

    const newMap = new Map<string, any[]>();
    for (let listItem of props.list) {
      let key = props.getNestingCriteriaFromListItem(listItem);
      if (newMap.has(key)) {
        newMap.get(key)?.push(listItem);
      } else {
        newMap.set(key, [listItem]);
      }
    }

    setNestedMap(newMap);
    setIsLoading(false); // ✅ Set loading to false after setting data
  }, [props.list, props.getNestingCriteriaFromListItem]);

  useEffect(() => {
    if (!props.list.length) return;

    const anySelected = props.list.find((entry) => props.isSelected(entry));
    if (anySelected) {
      const grouping = props.getNestingCriteriaFromListItem(anySelected);
      setOpenMap((prev) => ({ ...prev, [grouping]: true }));
    }
  }, [props.isSelected, props.list]);

  // ✅ Skeleton Loading UI
  if (isLoading) {
    return (
      <List sx={{ width: "100%", bgcolor: "background.paper" }} component="nav">
        {[1, 2, 3].map((index) => (
          <ListItemButton key={index}>
            <ListItemIcon>
              <Skeleton variant="circular" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={<Skeleton width="80%" />} />
          </ListItemButton>
        ))}
      </List>
    );
  }

  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }} component="nav">
      {Array.from(nestedMap.entries()).map(([key, items]) => (
        <div key={key}>
          <ListItemButton divider={true} onClick={() => toggleOpenState(key)}>
            <ListItemIcon sx={{mt:1}}>
              <Badge
                
                badgeContent={items.length}
                color="secondary"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                {openMap[key] ? <KeyboardArrowUp sx={{mr:0.3}} /> : <KeyboardArrowDown sx={{mr:0.3}}/>}
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                  {props.getNestingCriteriaVisualizer(items[0])}
                </Typography>
              }
            />
            <Box
              sx={{ position: "absolute", right: 0, mr: 3, zIndex: 5 }}
              onClick={(e) => e.stopPropagation()}
            >
              <GenericActionBar
                state={items[0]}
                actions={props.getNestingCriteriaActions(items[0])}
                threshold={0}
              />
            </Box>
          </ListItemButton>

          <Collapse in={openMap[key]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {items.map((item, index) => (
                <ListItemButton
                  divider={true}
                  key={key + "_" + index}
                  sx={{ pl: 4 }}
                  onClick={props.listItemOnClick(item)}
                  selected={props.isSelected(item)}
                >
                  <ListItemText
                    primary={
                      <Typography pr={2} sx={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                        {props.getListItemVisualizer(item)}
                      </Typography>
                    }
                  />
                  <Box
                    sx={{ position: "absolute", right: 0, mr: 4, zIndex: 5 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <GenericActionBar
                      state={item}
                      actions={props.getListItemActions(item)}
                      threshold={0}
                    />
                  </Box>
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </div>
      ))}
    </List>
  );
};