import {
    ChangeEvent,
    SyntheticEvent,
    useEffect, 
    useMemo, 
    useRef,
    useState
} from "react";
import {
    Pagination,
    Typography
} from "@mui/material";
import {useDispatch} from "react-redux";
import Grid from "@mui/material/Grid2";
import {GenericActionBarAction, GenericActionBar} from "./GenericActionBar";
import {GenericTextEditField} from "./GenericTextEditField";
import {GenericTable} from "./GenericTable/GenericTable";
import {GenericCheckbox} from "./GenericCheckbox";
import {GenericSelect} from "./GenericSelect/GenericSelect";
import {GenericTokenizer} from "./GenericTokenizer/GenericTokenizer";
import {UiDefinition} from "../UI-Builder/uiBuilderServices/uiDefinition";
import {AppDispatch} from "../../../store";
import {setIsDirty} from "../GenericUIViews/GenericMaintenanceList/GenericMaintenanceListState";
import {GenericIconField} from "./GenericIconField";
import {useTranslation} from "../custom-hooks/useTranslation";
import {GenericMaskedField} from "./GenericMaskedField";

export enum FieldTypes {
    "readOnlyField" = "readOnlyField",
    "textField" = "textField",
    "multiline" = "multiline",
    "dateField" = "dateField",
    "table" = "table",
    "object" = "object",
    "checkbox" = "checkbox",
    "numberField" = "numberField",
    "timeField" = "timeField",
    "selectField" = "selectField",
    "tokenizer" = "tokenizer",
    "icon" = "icon",
    "color" = "color",
    "password" = "password",
    "masked" = "masked"
}

export interface UiDescriptor {
    fieldName: string;
    fieldLabel?: string;
    fieldType:
        | "readOnlyField"
        | "textField"
        | "multiline"
        | "dateField"
        | "table"
        | "object"
        | "checkbox"
        | "numberField"
        | "timeField"
        | "selectField"
        | "tokenizer"
        | "icon"
        | "color"
        | "password"
        | "masked";
    visible: boolean;
    reference?: UiDefinition;
    section?: boolean;
    required: boolean;
    validator?: string;
    mask?: string;
    maskDefintion?: string;
    size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    childUIDescriptor?: UiDescriptor[];
}

export interface MaintenanceViewProps {
    data: any;
    uiDescriptorTable: UiDescriptor[];
    translationPrefix: string;
    titleSettings?: {
        title: string;
        shouldBeRendered: boolean;
    };
    actions: GenericActionBarAction[];
    updateBaseData?: (localData: any) => void;
    hideFieldsWithoutUiDescriptor: boolean;
    size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    asSection?: boolean;
}

export function MaintenanceView(props: MaintenanceViewProps) {
    const {t} = useTranslation();
    const [localData, setLocalData] = useState(props.data);
    const dispatch: AppDispatch = useDispatch();
    const ref = useRef<any>(null)
    const [selectedPage, setSelectedPage] = useState(1);
    //const [overflows, setOverflows] = useState(false);

   /*
   todo find a more consistent way to check for overflow, to prevent ui jumping
   useLayoutEffect(() => {
        if (ref.current) {
            const el = ref.current;
            const isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight
            console.log("Overflow", isOverflowing, el.clientHeight, el.scrollHeight)
            setOverflows(true)
        }

    }, [ref.current, ref.current?.scrollWidth, ref.current?.scrollHeight, props.data])*/


    useEffect(() => {
        setLocalData(props.data);
        //setOverflows(false);
        setSelectedPage(1);
    }, [props.data]);


    let uiComponents = useMemo(() => {

        if (!localData) {
            return [[<div/>], []]
        }
        const pageOne: any[] = []
        const pageTwo: any[] = []
        props.uiDescriptorTable.forEach((uiDescriptorLine) => {

            let uiDescriptor = {...uiDescriptorLine};
            let value = localData[uiDescriptor.fieldName];

            if (
                value instanceof Object &&
                value !== null &&
                uiDescriptor.fieldType !== "selectField" &&
                uiDescriptor.fieldType !== "tokenizer"
            ) {
                uiDescriptor.fieldType = "object";
            }

            if (Array.isArray(value) && uiDescriptor.fieldType !== "tokenizer") {
                uiDescriptor.fieldType = "table";
            }

            if (!uiDescriptor.visible && uiDescriptor.fieldType !== "object") {
                return <></>;
            }

            const updateBaseData = (data: any) => {

                let dataCopy = {...localData};
                dataCopy[uiDescriptor.fieldName] = data;

                if (props.updateBaseData) {
                    props.updateBaseData(dataCopy);
                } else {
                    setLocalData(dataCopy);
                }
            }


            const onChange = (value: any) => {
                let dataCopy = {...localData};
                dataCopy[uiDescriptor.fieldName] = value;
                if (props.updateBaseData) {
                    props.updateBaseData(dataCopy);
                } else {
                    setLocalData(dataCopy);
                }
                dispatch(setIsDirty(true));
            };

            const onChangeTextEdit = (
                e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
                onChange(e.currentTarget.value);
            };

            const onChangeCheckbox = (e: SyntheticEvent, checked: boolean) => {
                onChange(checked);
            };

            const onChangeSelect = (e: any) => {
                onChange(e);
            };

            const onChangeIconField = (e: string) => {
                onChange(e);
            };

            const onChangeTokenizer = (e: string[]) => {
                onChange(e);
            };

            switch (uiDescriptor.fieldType) {
                case "textField":
                    pageOne.push(
                        <GenericTextEditField
                            key={uiDescriptor.fieldName}
                            translationPrefix={props.translationPrefix}
                            fieldKey={uiDescriptor.fieldName}
                            value={localData[uiDescriptor.fieldName]}
                            required={uiDescriptor.required}
                            uiDescriptor={uiDescriptor}
                            onChange={onChangeTextEdit}
                            type={"text"}
                        />
                    );
                    return;
                case "multiline":
                    pageOne.push(
                        <GenericTextEditField
                            key={uiDescriptor.fieldName}
                            translationPrefix={props.translationPrefix}
                            fieldKey={uiDescriptor.fieldName}
                            value={localData[uiDescriptor.fieldName]}
                            required={uiDescriptor.required}
                            uiDescriptor={uiDescriptor}
                            onChange={onChangeTextEdit}
                            multiline={true}
                            type={"text"}
                        />
                    )
                    return;
                case "masked":
                    pageOne.push(<GenericMaskedField type={"text"}
                                                     key={uiDescriptor.fieldName}
                                                     fieldKey={uiDescriptor.fieldName}
                                                     value={localData[uiDescriptor.fieldName]}
                                                     required={uiDescriptor.required}
                                                     uiDescriptor={uiDescriptor}
                                                     translationPrefix={props.translationPrefix}
                                                     onChange={onChangeTextEdit}/>)
                    return;
                case "password":
                    pageOne.push(
                        <GenericTextEditField
                            key={uiDescriptor.fieldName}
                            translationPrefix={props.translationPrefix}
                            fieldKey={uiDescriptor.fieldName}
                            value={localData[uiDescriptor.fieldName]}
                            required={uiDescriptor.required}
                            uiDescriptor={uiDescriptor}
                            onChange={onChangeTextEdit}
                            type={"password"}
                        />
                    );
                    return
                case "color":
                    pageOne.push(
                        <GenericTextEditField
                            key={uiDescriptor.fieldName}
                            translationPrefix={props.translationPrefix}
                            fieldKey={uiDescriptor.fieldName}
                            value={localData[uiDescriptor.fieldName]}
                            required={uiDescriptor.required}
                            uiDescriptor={uiDescriptor}
                            onChange={onChangeTextEdit}
                            type={"color"}
                        />
                    );
                    return
                case "icon":
                    pageOne.push(
                        <GenericIconField
                            key={uiDescriptor.fieldName}
                            translationPrefix={props.translationPrefix}
                            onChange={onChangeIconField}
                            fieldKey={uiDescriptor.fieldName}
                            value={localData[uiDescriptor.fieldName]}
                            required={uiDescriptor.required}
                            uiDescriptor={uiDescriptor}
                        />
                    );
                    return
                case "dateField":
                    pageOne.push(
                        <GenericTextEditField
                            key={uiDescriptor.fieldName}
                            translationPrefix={props.translationPrefix}
                            fieldKey={uiDescriptor.fieldName}
                            value={localData[uiDescriptor.fieldName]}
                            required={uiDescriptor.required}
                            uiDescriptor={uiDescriptor}
                            onChange={onChangeTextEdit}
                            type={"date"}
                        />
                    );
                    return

                case "numberField":
                    pageOne.push(
                        <GenericTextEditField
                            key={uiDescriptor.fieldName}
                            translationPrefix={props.translationPrefix}
                            fieldKey={uiDescriptor.fieldName}
                            value={localData[uiDescriptor.fieldName]}
                            uiDescriptor={uiDescriptor}
                            required={uiDescriptor.required}
                            onChange={onChangeTextEdit}
                            type={"number"}
                        />
                    );
                    return
                case "timeField":
                    pageOne.push(
                        <GenericTextEditField
                            key={uiDescriptor.fieldName}
                            translationPrefix={props.translationPrefix}
                            fieldKey={uiDescriptor.fieldName}
                            value={localData[uiDescriptor.fieldName]}
                            uiDescriptor={uiDescriptor}
                            required={uiDescriptor.required}
                            onChange={onChangeTextEdit}
                            type={"time"}
                        />
                    );
                    return
                case "selectField":
                    pageOne.push(
                        <GenericSelect
                            key={uiDescriptor.fieldName}
                            translationPrefix={props.translationPrefix}
                            fieldKey={uiDescriptor.fieldName}
                            value={localData[uiDescriptor.fieldName]}
                            uiDescriptor={uiDescriptor}
                            required={uiDescriptor.required}
                            onChange={onChangeSelect}
                        />
                    );
                    return
                case "readOnlyField":
                    pageOne.push(
                        <GenericTextEditField
                            key={uiDescriptor.fieldName}
                            translationPrefix={props.translationPrefix}
                            fieldKey={uiDescriptor.fieldName}
                            value={localData[uiDescriptor.fieldName]}
                            disabled={true}
                            required={uiDescriptor.required}
                            uiDescriptor={uiDescriptor}
                            type={"text"}
                        />
                    );
                    return
                case "checkbox":
                    pageOne.push(
                        <GenericCheckbox
                            key={uiDescriptor.fieldName}
                            translationPrefix={props.translationPrefix}
                            onChange={onChangeCheckbox}
                            fieldKey={uiDescriptor.fieldName}
                            value={localData[uiDescriptor.fieldName]}
                            required={uiDescriptor.required}
                            uiDescriptor={uiDescriptor}
                        />
                    );
                    return
                case "table":
                    const table =
                        <GenericTable
                            key={uiDescriptor.fieldName}
                            translationPrefix={`${props.translationPrefix}.${uiDescriptor.fieldName}`}
                            table={localData[uiDescriptor.fieldName] || []}
                            uiDescriptorTable={uiDescriptor.childUIDescriptor || []}
                            overflows={true}
                            updateTable={(table) => {
                                let copy = {...localData};
                                copy[uiDescriptor.fieldName] = table;
                                if (props.updateBaseData) {
                                    props.updateBaseData(copy);
                                } else {
                                    setLocalData(copy);
                                }
                            }}
                        />
                    pageTwo.push(table)
                    return
                case "object":
                    pageOne.push(
                        <MaintenanceView
                            key={uiDescriptor.fieldName}
                            translationPrefix={`${props.translationPrefix}.${uiDescriptor.fieldName}`}
                            data={localData[uiDescriptor.fieldName] || {}}
                            uiDescriptorTable={uiDescriptor.childUIDescriptor || []}
                            actions={[]}
                            titleSettings={{
                                shouldBeRendered: uiDescriptor.visible,
                                title: uiDescriptor.fieldLabel || "",
                            }}
                            asSection={uiDescriptor?.section}
                            hideFieldsWithoutUiDescriptor={props.hideFieldsWithoutUiDescriptor}
                            updateBaseData={updateBaseData}
                            size={uiDescriptor.size}
                        />
                    );
                    return

                case "tokenizer":
                    pageOne.push(
                        <GenericTokenizer
                            key={uiDescriptor.fieldName}
                            translationPrefix={props.translationPrefix}
                            fieldKey={uiDescriptor.fieldName}
                            value={localData[uiDescriptor.fieldName] || []}
                            uiDescriptor={uiDescriptor}
                            required={uiDescriptor.required}
                            onChange={onChangeTokenizer}
                        />
                    );
                    return

                default:
                    pageOne.push(
                        <GenericTextEditField
                            key={uiDescriptor.fieldName}
                            translationPrefix={props.translationPrefix}
                            fieldKey={uiDescriptor.fieldName}
                            value={localData[uiDescriptor.fieldName]}
                            uiDescriptor={uiDescriptor}
                            required={uiDescriptor.required}
                            onChange={onChangeTextEdit}
                            type={"text"}
                        />
                    );
                    return;
            }

        })
        return [pageOne, pageTwo]

    }, [props.uiDescriptorTable, localData])

    return props.asSection ? (
        <Grid
            key={"MaintenanceView_SectionGrid"}
            container
            spacing={2}
            size={{ xs:props?.size || 12 }}
            alignContent={"center"}
            alignItems={"center"}
            sx={{maxHeight: "100%"}}
            ref={ref}

        >
            {props?.titleSettings?.shouldBeRendered ? (
                <Grid size={{ xs: 12 }}>
                    <Typography mt={2} ml={1}>{t(props.titleSettings.title)} </Typography>
                </Grid>
            ) : (
                <></>
            )}
            <GenericActionBar state={localData} actions={props.actions}/>
            {( uiComponents[1]?.length > 0) ?
                <Grid size={{ xs: 12 }} key={"Pagination"}> <Pagination size={"small"} sx={{width: "100%"}} page={selectedPage} defaultPage={1}
                                                onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                                                    setSelectedPage(value);
                                                }} count={2} color="primary"/> </Grid> : <></>}
            <Grid
                container
                size={{ xs:12 }}
                key={"MaintenanceView_SelectedPage"}
                spacing={2}
                sx={{height: "100%", width: "100%", maxHeight: "100%"}}


            >
                {selectedPage === 1 ? uiComponents[0] : uiComponents[1]}
            </Grid>

        </Grid>
    ) : (
        <>{uiComponents[0]}</>
    );
}
