import { useGetUiEnhancers } from "./components/custom-hooks/useGetUIEnhancers";
import MapBasedDashboard from "./components/map/MapBasedDashboard";
import {GraphicEq, Hub, MapRounded} from "@mui/icons-material";
import LandingPageTile from "./components/MaterialOnFire/landing-page/LandingPageTile";
import { MaterialOnFire } from "./components/MaterialOnFire/MaterialOnFire";
import { firebaseConfig } from "./firebaseConfig";
import { CreateLoraNodeWizardWrapper } from "./components/custom-hooks/uiEnhancers/createNewLoraDeviceWizzard/CreateLoraNodeWizardWrapper";
import {NodePage} from "./components/nodeDetails/NodePage/NodePage";

function App() {
  const uiEnhancers = useGetUiEnhancers();
  return (
    <MaterialOnFire
      uiEnhancers={uiEnhancers}
      firebaseConfig={firebaseConfig}
      customRoutes={[
        {
          route: "/map",
          name: "map",
          component: <MapBasedDashboard />,
          icon: <MapRounded />,
          landingPageTile: (
            <LandingPageTile
              name={"landingPage.map"}
              link={"/map"}
              Icon={"MapRounded"}
            />
          )
        },
          {
            route: "/NodePage",
              name:"NodePage",
              component: <NodePage/>,
                icon: <Hub/>,
              landingPageTile: (
                <LandingPageTile
                  name={"landingPage.NodePage"}
                  link={"/NodePage"}
                  Icon={"Hub"}
                />
              )
          },
        {
          route: "/newDeviceWizard",
          name: "wizard",
          component: <CreateLoraNodeWizardWrapper />,
          icon: <GraphicEq />,
          landingPageTile: (
            <LandingPageTile
              name={"landingPage.wizard"}
              link={"/newDeviceWizard"}
              Icon={"GraphicEq"}
            />
          ),
        },
      ]}
    />
  );
}

export default App;
