import { Wizard } from "./Wizard";
import { ReactNode, useState } from "react";
import { ChooseEndpointStep } from "./createNewLoraNodeWizardSteps/ChooseEndpointStep";
import { ProvideDeviceInformationStep } from "./createNewLoraNodeWizardSteps/ProvideDeviceInformationStep";
import { ProvideDeviceDetailsStep } from "./createNewLoraNodeWizardSteps/ProvideDeviceDetailsStep";
import { ChooseLoraVersionDetailsStep } from "./createNewLoraNodeWizardSteps/ChooseLoraVersionDetailsStep";
import { FinishStep } from "./createNewLoraNodeWizardSteps/FinishStep";
import {HardwareType} from "../../../../model/hardware-type/hardwareType";
import {Group} from "../../../../model/groups/groupState";

export type TCreateLoraNodeDataModel = {
  //step1
  applicationId?: string;

  //step2
  activationType?: string | "ABP" | "OTAA";
  multicast?: boolean;
  deviceId?: string;
  dev_addr?: string;
  dev_eui?: string;
  join_eui?: string;
  application_root_key?: string;
  network_session_key?: string;

  //step3
  lat?: string;
  lng?: string;
  hardwareType?: HardwareType;
  group? : Group;

  //step4
  lora_version?: string;
  lora_phy_version?: string;
  frequency_id?: string;

  [key: string]: string | undefined | boolean | HardwareType | Group;
};

export type TFrequencyPlan = {
  id: string;
  name: string;
  bandId: string;
  baseFrequency: string;
  description: string;
};

export const loraVersion = {
  MAC_UNKNOWN: "MAC_UNKNOWN",
  MAC_V1_0: "MAC_V1_0",
  MAC_V1_0_1: "MAC_V1_0_1",
  MAC_V1_0_2: "MAC_V1_0_2",
  MAC_V1_1: "MAC_V1_1",
  MAC_V1_0_3: "MAC_V1_0_3",
  MAC_V1_0_4: "MAC_V1_0_4",
};

export const loraPhyVersion = {
  PHY_UNKNOWN: "PHY_UNKNOWN",
  PHY_V1_0: "PHY_V1_0",
  TS001_V1_0: "TS001_V1_0",
  PHY_V1_0_1: "PHY_V1_0_1",
  TS001_V1_0_1: "TS001_V1_0_1",
  PHY_V1_0_2_REV_A: "PHY_V1_0_2_REV_A",
  RP001_V1_0_2: "RP001_V1_0_2",
  PHY_V1_0_2_REV_B: "PHY_V1_0_2_REV_B",
  RP001_V1_0_2_REV_B: "RP001_V1_0_2_REV_B",
  PHY_V1_1_REV_A: "PHY_V1_1_REV_A",
  RP001_V1_1_REV_A: "RP001_V1_1_REV_A",
  PHY_V1_1_REV_B: "PHY_V1_1_REV_B",
  RP001_V1_1_REV_B: "RP001_V1_1_REV_B",
  PHY_V1_0_3_REV_A: "PHY_V1_0_3_REV_A",
  RP001_V1_0_3_REV_A: "RP001_V1_0_3_REV_A",
  RP002_V1_0_0: "RP002_V1_0_0",
  RP002_V1_0_1: "RP002_V1_0_1",
  RP002_V1_0_2: "RP002_V1_0_2",
  RP002_V1_0_3: "RP002_V1_0_3",
};

export const frequencyPlans = {
  EU_863_870: {
    id: "EU_863_870",
    name: "Europe 863-870 MHz (SF12 for RX2)",
    bandId: "EU_863_870",
    baseFrequency: "868",
    description: "Default frequency plan for Europe",
  } as TFrequencyPlan,
  EU_863_870_TTN: {
    id: "EU_863_870_TTN",
    name: "Europe 863-870 MHz (SF9 for RX2 - recommended)",
    bandId: "EU_863_870",
    baseFrequency: "868",
    description:
      "TTN Community Network frequency plan for Europe, using SF9 for RX2",
  } as TFrequencyPlan,
  EU_863_870_ROAMING_DRAFT: {
    id: "EU_863_870_ROAMING_DRAFT",
    name: "Europe 863-870 MHz, 6 channels for roaming (Draft)",
    bandId: "EU_863_870",
    baseFrequency: "868",
    description:
      "European 6 channel plan used by major operators to support LoRaWAN Passive Roaming",
  } as TFrequencyPlan,
  EU_433: {
    id: "EU_433",
    name: "Europe 433 MHz (ITU region 1)",
    bandId: "EU_433",
    baseFrequency: "433",
    description: "Default frequency plan for worldwide 433MHz",
  } as TFrequencyPlan,
};

interface ICreateLoraNodeWizardWrapper {
  //This callback is dynamically added by the framework, interface is just for visibility
  closeCallback?: () => void;
}

export const CreateLoraNodeWizardWrapper = (
  props: ICreateLoraNodeWizardWrapper
) => {
  const [newLoraNode, setNewLoraNode] = useState<TCreateLoraNodeDataModel>({
    activationType: "OTAA",
    multicast: false,
    lora_version: loraVersion.MAC_V1_0_3,
    lora_phy_version: loraPhyVersion.RP001_V1_0_3_REV_A,
    frequency_id: frequencyPlans.EU_863_870.id,
  });

  const verifyIfDeviceIsValid = (): boolean => {
    if (newLoraNode.activationType === "ABP") {
      if (newLoraNode.multicast === true) {
        return isAbpMulticastDeviceValid();
      }
      return isAbpDeviceValid();
    }

    return isOtaaDeviceValid();
  };

  const isOtaaDeviceValid = () => {
    return (
      !!newLoraNode.deviceId &&
      newLoraNode.deviceId.length > 0 &&
      !!newLoraNode.dev_eui &&
      newLoraNode.dev_eui.replaceAll(" ", "").length === 16 &&
      !!newLoraNode.join_eui &&
      newLoraNode.join_eui.replaceAll(" ", "").length === 16 &&
      !!newLoraNode.application_root_key &&
      newLoraNode.application_root_key.replaceAll(" ", "").length === 32
    );
  };

  const isAbpDeviceValid = () => {
    const dev_addr =
        !!newLoraNode.dev_addr &&
        newLoraNode.dev_addr.replaceAll(" ", "").length === 8;

    return (
      !!newLoraNode.deviceId &&
      newLoraNode.deviceId.length > 0 &&
      !!newLoraNode.dev_eui &&
      newLoraNode.dev_eui.replaceAll(" ", "").length === 16 &&
      !!newLoraNode.application_root_key &&
      newLoraNode.application_root_key.replaceAll(" ", "").length === 32 &&
      !!newLoraNode.network_session_key &&
      newLoraNode.network_session_key.replaceAll(" ", "").length === 32 && dev_addr
    );
  };

  const isAbpMulticastDeviceValid = () => {
    const device_id = (!!newLoraNode.deviceId &&
      newLoraNode.deviceId.length > 0) as boolean;
    const dev_addr =
      !!newLoraNode.dev_addr &&
      newLoraNode.dev_addr.replaceAll(" ", "").length === 8;
    const application_root_key =
      !!newLoraNode.application_root_key &&
      newLoraNode.application_root_key.replaceAll(" ", "").length === 32;
    const networkKey =
      !!newLoraNode.network_session_key &&
      newLoraNode.network_session_key.replaceAll(" ", "").length === 32;

    return device_id && dev_addr && application_root_key && networkKey;
  };

  return (
    <Wizard
      wizardName={"createLoraNode"}
      closeCallback={props.closeCallback}
      onFinishComponent={
        <FinishStep
          newLoraNode={newLoraNode}
          updateNewLoraNode={setNewLoraNode}
          closeOnSuccess={props.closeCallback}
        />
      }
      steps={[
        {
          jsx: (
            <ChooseEndpointStep
              newLoraNode={newLoraNode}
              updateNewLoraNode={setNewLoraNode}
            />
          ),
          isOptional: false,
          label: "Choose Endpoint",
          validateSelf: () => {
            const isValid =
              !!newLoraNode.applicationId && newLoraNode.applicationId !== "";
            return {
              isValid: isValid,
              messageKey: isValid
                ? undefined
                : "createLoraNodeWizard.chooseEndpointStep.errorMessage",
            };
          },
        },
        {
          jsx: (
            <ProvideDeviceInformationStep
              newLoraNode={newLoraNode}
              updateNewLoraNode={setNewLoraNode}
            />
          ),
          isOptional: false,
          label: "Provide Device Information",
          validateSelf: () => {
            const isValid = verifyIfDeviceIsValid();

            return {
              isValid: isValid,
              messageKey: isValid
                ? undefined
                : "createLoraNodeWizard.provideDeviceDetailsStep.errorMessage",
            };
          },
        },
        {
          jsx: (
            <ProvideDeviceDetailsStep
              newLoraNode={newLoraNode}
              updateNewLoraNode={setNewLoraNode}
            />
          ),
          isOptional: false,
          label: "Provide Device Details ",
          validateSelf: () => {
            const isValid =
              !!newLoraNode.lng &&
              newLoraNode.lng !== "" &&
              !!newLoraNode.lat &&
              newLoraNode.lat !== "" && !!newLoraNode.hardwareType;
            return {
              isValid: isValid,
              messageKey: isValid
                ? undefined
                : "createLoraNodeWizard.provideDeviceDetailsStep.errorMessage",
            };
          },
        },
        {
          jsx: (
            <ChooseLoraVersionDetailsStep
              newLoraNode={newLoraNode}
              updateNewLoraNode={setNewLoraNode}
            />
          ),
          isOptional: true,
          label: "Choose Lora Version Details",
          validateSelf: () => {
            return {
              isValid: true,
            };
          },
        },
      ]}
    />
  );
};
