import { getAuth ,
  sendEmailVerification } from "firebase/auth";
import {
  fireBaseApp,
  fireBaseAppInterface,
  initFireBaseApp,
} from "./firebase-config";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { useGetUiEnhancers } from "../custom-hooks/useGetUIEnhancers";
import { changeLoggedInStatus, KapionUser, setUser } from "./login/userSate";
import { responsiveFontSizes, ThemeProvider } from "@mui/material";
import { getTheme } from "./theme/MainTheme";
import Login from "./login/Login";
import { BrowserRouter } from "react-router-dom";
import { DynamicRoutes } from "./DynamicRoutes";
import MapBasedDashboard from "../map/MapBasedDashboard";
import { MapRounded } from "@mui/icons-material";
import LandingPageTile from "./landing-page/LandingPageTile";
import React from "react";
import { CustomRoute, UiEnhancements } from "./custom-hooks/useGetRoutes";
import { useGetTheme } from "./custom-hooks/useGetTheme";
import { fetchAllTranslations } from "./translations/translationsState";
import { LoadingScreen } from "./LoadingScreen/LoadingScreen";
import { MailNotVerified } from "./login/MailNotVerified";
import {useRegisterAlertPipelines} from "../custom-hooks/useRegisterAlertPipelines";

interface MaterialOnFireInterface {
  firebaseConfig: fireBaseAppInterface;
  uiEnhancers?: Map<string, UiEnhancements>;
  customRoutes?: CustomRoute[];
}

export const MaterialOnFire = (props: MaterialOnFireInterface) => {
  initFireBaseApp(props.firebaseConfig);
    //register alert pipelines

    const auth = getAuth(fireBaseApp);

  const dispatch: AppDispatch = useDispatch();

  const loggedin = useSelector((state: RootState) => state.user.loggedIn);
  const mailVerified = useSelector( (state:RootState) => state.user.user?.verified)
  const selectedTheme = useSelector(
    (state: RootState) => state.appBar.themeMode
  );

  const theme = useGetTheme();

  auth.onAuthStateChanged(async (value) => {
    if (value) {
      const tokenResult = await value.getIdTokenResult();
      dispatch(
        setUser({
          email: value.email || "",
          displayName: value.displayName || "",
          verified: value.emailVerified,
          uid: value.uid,
          claims: tokenResult.claims,
        } as KapionUser)
      );
    } else {
      dispatch(setUser(null));
    }

    dispatch(changeLoggedInStatus(!!value));
  });

  return !loggedin ? (
    <ThemeProvider theme={responsiveFontSizes(getTheme(selectedTheme))}>
      <Login />
    </ThemeProvider>
  ) :  !mailVerified ? <MailNotVerified/>:(
    <>
      <LoadingScreen
        uiEnhancers={props.uiEnhancers}
        customRoutes={props.customRoutes}
      />
    </>
  );
};
