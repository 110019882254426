import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {frequencyPlans, loraPhyVersion, loraVersion, TCreateLoraNodeDataModel} from "../CreateLoraNodeWizardWrapper";
import {useTranslation} from "../../../../MaterialOnFire/custom-hooks/useTranslation";
import Grid from "@mui/material/Grid2";

export const ChooseLoraVersionDetailsStep = (props: {
    newLoraNode: TCreateLoraNodeDataModel;
    updateNewLoraNode: (newState: TCreateLoraNodeDataModel) => void;
}) => {
    const {t} = useTranslation();


    return (
        <Grid
            sx={{
                height: "100%",
                width: "100%",
                padding: 1,
            }}
            spacing={2}
            container
            alignContent={"flex-start"}
            //justifyContent={"center"}
        >
            <Grid  size={{xs:6}}>
                <FormControl fullWidth required={true}>
                    <InputLabel>
                        {t(
                            "createLoraNodeWizard.ChooseLoraVersionDetailsStep.selectLoraVersion"
                        )}
                    </InputLabel>
                    <Select
                        value={props.newLoraNode?.lora_version || ""}
                        label={t(
                            "createLoraNodeWizard.ChooseLoraVersionDetailsStep.selectLoraVersion"
                        )}
                    >
                        {
                            Object.entries(loraVersion).map(version => {
                                return <MenuItem
                                    key={`menuItem-${version[0]}`}
                                    value={version[0]}
                                    onClick={() =>
                                        props.updateNewLoraNode({
                                            ...props.newLoraNode,
                                            lora_version: version[0],
                                        })
                                    }
                                >
                                    {version[1]}
                                </MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid size={{xs:6}}>
                <FormControl fullWidth required={true}>
                    <InputLabel>
                        {t(
                            "createLoraNodeWizard.ChooseLoraVersionDetailsStep.selectLoraPhyVersion"
                        )}
                    </InputLabel>
                    <Select
                        value={props.newLoraNode?.lora_phy_version || ""}
                        label={t(
                            "createLoraNodeWizard.ChooseLoraVersionDetailsStep.selectLoraPhyVersion"
                        )}
                    >
                        {
                            Object.entries(loraPhyVersion).map(phyVersion => {
                                return <MenuItem
                                    key={`menuItem-${phyVersion[0]}`}
                                    value={phyVersion[0]}
                                    onClick={() =>
                                        props.updateNewLoraNode({
                                            ...props.newLoraNode,
                                            lora_phy_version: phyVersion[0],
                                        })
                                    }
                                >
                                    {phyVersion[1]}
                                </MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid size={{xs:6}}>
                <FormControl fullWidth required={true}>
                    <InputLabel>
                        {t(
                            "createLoraNodeWizard.ChooseLoraVersionDetailsStep.selectFrequency"
                        )}
                    </InputLabel>
                    <Select
                        value={props.newLoraNode?.frequency_id || ""}
                        label={t(
                            "createLoraNodeWizard.ChooseLoraVersionDetailsStep.selectFrequency"
                        )}
                    >
                        {
                            Object.entries(frequencyPlans).map(frequencyPlan => {
                                return <MenuItem
                                    key={`menuItem-${frequencyPlan[0]}`}
                                    value={frequencyPlan[0]}
                                                 onClick={() =>
                                                     props.updateNewLoraNode({
                                                         ...props.newLoraNode,
                                                         frequency_id: frequencyPlan[0],
                                                     })
                                                 }
                                >
                                    {frequencyPlan[1].name}
                                </MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};
