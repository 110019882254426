enum QrCodeType {
  UNKNOWN = "UNKNOWN",
  JSON = "JSON",
  LorA = "LoRaWAN",
}

/* LoRaWAN QR Code definition
244 4.3 Example QR Codes
245
246 Given:
247
248 SchemaID of D0
249 JoinEUI of 11-22-33-44-55-66-77-88
250 DevEUI of AA-BB-CC-DD-EE-FF-00-11
251 ProfileID of AABB-1122
252 OwnerToken of AABBCCDDEEFF starts with O
253 SerNum of YYWWNNNNNN starts with S
254 Proprietary of FOOBAR starts with P
255 CheckSum of AF2C starts with C
256
257 This requires size 4 and can only have ECC=Medium.
258
259
260 Here are the 88 bytes of data:
261
262 LW:D0:1122334455667788:AABBCCDDEEFF0011:AABB1122:OAABBCCDDEEFF:
263 SYYWWNNNNNN:PFOOBAR:CAF2C
 */

const findOwnerTokenRegex = /:O([A-F0-9]{12})/;
const findSerNumRegex = /:S([A-F0-9]{10})/;
const findProprietaryRegex = /:P([A-F0-9]+:)/;
const findChecksumRegex = /:C([A-F0-9]{4})$/;

const classifyQrCode = (qrCode: string): QrCodeType => {
  if (qrCode.startsWith("LW:")) {
    return QrCodeType.LorA;
  }
  try {
    JSON.parse(qrCode);
    return QrCodeType.JSON;
  } catch (e) {
    return QrCodeType.UNKNOWN;
  }
};

const parseLorA = (qrCode: string) => {
  const parts = qrCode.split(":");
  if (parts.length !== 9) {
    throw new Error("Invalid QR Code");
  }

  const extract = {
    schemaId: parts[1],
    join_eui: parts[2],
    dev_eui: parts[3],
    profileId: parts[4],
    ownerToken: qrCode.match(findOwnerTokenRegex)?.[1] || "",
    serNum: qrCode.match(findSerNumRegex)?.[1] || "",
    proprietary: qrCode.match(findProprietaryRegex)?.[1] || "",
    checksum: qrCode.match(findChecksumRegex)?.[1] || "",
  };
  return extract;
};

export const extractQRCodeData = (qrCode: string) => {
  switch (classifyQrCode(qrCode)) {
    case QrCodeType.LorA:
      return parseLorA(qrCode);
    case QrCodeType.JSON:
      return JSON.parse(qrCode);
    default:
      return {};
  }
};
