import {
    Badge, Box,
    CircularProgress,
    Divider,
    Tooltip,
    Typography,
} from "@mui/material";
import { Provider } from "react-redux";
import { store } from "../../store";
import { SnackbarProvider} from "notistack";
import { NodeDetails } from "../nodeDetails/NodeDetails";
import { HardwareType } from "../../model/hardware-type/hardwareType";
import { NavigateOptions } from "react-router-dom";
import { LoraNode } from "../../model/lora-node/LoraNode";
import { useGetNodeStatus } from "../custom-hooks/useGetNodeStatus";
import {Check, Error, NotListedLocation, Warning} from "@mui/icons-material";
import { useTranslation } from "../MaterialOnFire/custom-hooks/useTranslation";

export const MapPopup = (props: {
  id: string;
  node: LoraNode;
  hardwareType?: HardwareType;
  enqueueSnackbar: (message: string) => void;
  closeSnackbar: (message: string) => void;
  navigate: (prefix:string, basePath:string, targetElement:string, options?:NavigateOptions) => void;
}) => {
  const status = useGetNodeStatus({ id: props.id });
  const { t } = useTranslation();
  return (
    <Box  sx={{ minWidth: "500px" ,minHeight:"350px"}} alignItems={"flex-start"} display={"flex"} flexDirection={"column"}>
      <Box
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ width: "100%", height:"100%" }}
        display={"flex"}

      >
        <Badge
          badgeContent={
            <Tooltip
              title={
                <>
                  {" "}
                  {t("NodeDetails.LastTransferred")}
                  {(status.timeSinceLastMessage / 60 / 60 / 1000).toFixed(
                    2
                  )}{" "}
                  {t("Stunden")}{" "}
                </>
              }
            >
              {status.status === "OK" ? (
                <Check color={"success"} />
              ) : status.status === "WARNING" ? (
                <Warning color={"warning"} />
              ) : status.status === "ERROR" ? (
                <Error color={"error"} />
              ) : status.status === "NEVER" ? (
                  <NotListedLocation color={"error"} />
                  )
                  : (
                <CircularProgress />
              )}
            </Tooltip>
          }
        >
          <Typography
            variant={"h5"}
            align={"center"}
            sx={{ cursor: "pointer", width: "100%" }}
            onClick={() =>
              props.navigate("","loraNodes",props.node.ttnPayload.ids.dev_eui)
            }
          >
            {props.node.name}
          </Typography>
        </Badge>
        <Divider />
      </Box>
      <Box flexGrow={1} justifyContent={"center"} alignItems={"center"} sx={{width:"100%", overflow: "auto" , height:"25rem"}}>
        <Provider store={store}>
          <SnackbarProvider>
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{width:"100%", height:"25rem", maxHeight:"100%",}}>
            <NodeDetails
              key={props.id}
              id={props.id}
              kpiCardSize={4}
              toolbar={{
                showToolbar: true,
                hardwareType: props?.hardwareType,
                node: props.node,
                enqueueSnackbar: props.enqueueSnackbar,
                closeSnackbar: props.closeSnackbar,
              }}
            />
              </Box>
          </SnackbarProvider>
        </Provider>
      </Box>
    </Box>
  );
};
