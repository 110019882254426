import {UiEnhancements} from "../../MaterialOnFire/custom-hooks/useGetRoutes";
import {EventsUI} from "./EventUI/EventsUI";
import {DiagramContainer} from "./Dashboards/DiagramContainer";
import {Box} from "@mui/material";

export const useGetDashboardUIEnhancer = () => {


    const dashboardUIEnhancer = {
        replacementJSX: ( props  ) =>{

            return <DiagramContainer {...props} width={"100%"} height={"100%"} dashboard={props.data!} columns={20}  />
        } } as UiEnhancements;

    return { key: "Dashboards", enhancer: dashboardUIEnhancer };


}