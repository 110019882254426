import * as React from "react";
import { IMaskInput } from "react-imask";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const HexMask = React.forwardRef<HTMLElement, CustomProps>(
  function HexMask(props, ref: any) {
    const { onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask={Array(32).fill('##').join(' ')}
        unmask={true}
        definitions={{
          "#": /[0-9A-Fa-f]/,
        }}
        inputRef={ref}
        overwrite={false}
        onAccept={(value, mask) => {
          onChange({ target: { name: props.name, value: value as string } });
        }}
      />
    );
  }
);
