import {useEffect, useMemo, useState} from "react";
import {
    Box,
    IconButton,
    Stack, Tab,
    ThemeProvider,
    Tooltip,
    Tabs,
    Paper, Typography
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {useTranslation} from "../MaterialOnFire/custom-hooks/useTranslation";
import {getLoraNodeActionsFromHardWareType} from "../custom-hooks/uiEnhancers/MaintainLoraNodeActions";
import {HardwareType} from "../../model/hardware-type/hardwareType";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {useGetTheme} from "../MaterialOnFire/custom-hooks/useGetTheme";
import {LoraNode} from "../../model/lora-node/LoraNode";
import {KPICard} from "./KPI-Card";
import {useGetDeviceTimeSeries} from "./useGetDeviceTimeSeries";
import {KapionLineChart} from "../custom-hooks/uiEnhancers/Dashboards/Diagrams/DiagramTypes/KapionLineChart";
import {CloseFullscreen} from "@mui/icons-material";

export interface KPIEntry {
    value: string;
    unit: string;
    timestamp: string;
    formatted: string;
    name: string;
    type: string;
    groupKey?: string;
}

export interface KPIDocument {
    [key: string]: KPIEntry;
}

export interface GroupedKPIDocument {
    [key: string]: KPIDocument;
}

export const NodeDetails = (props: {
    id: string;
    kpiCardSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    toolbar?: {
        showToolbar: boolean;
        hardwareType?: HardwareType;
        node: LoraNode;
        enqueueSnackbar: (text: string, options: any) => void;
        closeSnackbar: (text: string, options: any) => void;
    };
    maxWidth?: string,
    maxHeight?: string,
}) => {
    const [kpiLoading, kpIData, lastData] = useGetDeviceTimeSeries(props.id)
    const [selectedTab, setSelectedTab] = useState(0)

    const {t} = useTranslation()
    const theme = useGetTheme();

    const keyArray = useMemo(() => {
        const keys = lastData ? Object.keys(lastData).sort((a, b) => a.localeCompare(b)) : []

        const regularKeys = keys.filter(key => !key.startsWith("Gateway") && !["geohash", "latitude", "longitude"].includes(key))
        const gatewayKeys = keys.filter(key => key.startsWith("Gateway"))
        const geolocationKeys = keys.filter(key => ["geohash", "latitude", "longitude"].includes(key))

        return [regularKeys, gatewayKeys, geolocationKeys]
    }, [lastData])

    const [selectedKPI, setSelectedKPI] = useState<string | null>(null);


    const cachedHardwaretypes = useSelector(
        (
            state: RootState // @ts-ignore
        ) => state["hardwareTypes"]?.items as unknown as HardwareType[]
    );

    useEffect(() => {
        setSelectedTab(0)
    }, [props.id]);

    const toolbarActions = useMemo(
        () =>
            props.toolbar?.hardwareType
                ? getLoraNodeActionsFromHardWareType(
                    props.toolbar?.hardwareType,
                    cachedHardwaretypes,
                    //@ts-ignore
                    props.toolbar.enqueueSnackbar,
                    props.toolbar.closeSnackbar
                )
                : [],
                [props.toolbar?.hardwareType, cachedHardwaretypes]
    );

    return (
        <ThemeProvider theme={theme}>
            <Stack sx={{width: "100%", height: "100%"}} overflow={"hidden"} alignItems={"center"} padding={0}>
                <Grid container>
                    {props.toolbar?.showToolbar
                        ? toolbarActions?.map((action) => {
                            return (
                                <Grid size={{ xs: 1 }} mx={2} key={action.name}>
                                    <Tooltip title={action.name}>
                                        <IconButton
                                            disabled={action.disabled}
                                            color={"secondary"}
                                            onClick={() => action.action(props.toolbar?.node)}
                                        >
                                            {" "}
                                            {action.icon}{" "}
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            );
                        })
                        : null}
                </Grid>

                {

                    selectedKPI ?
                        <Box key={"chart"} width={"100%"} height={"100%"} gap="0.5rem" display={"flex"}
                             justifyContent={"center"} flexWrap={"wrap"} flexBasis={"100%"} alignContent={"flex-start"}
                             flexDirection={"row"} overflow={"hidden"} paddingBottom={"0.5rem"}>

                            <Box width={"100%"} height={"3rem"} position={"relative"}>
                                <Typography width={"100%"} align={"center"} variant={"h4"}>{selectedKPI}</Typography>
                                <IconButton sx={{position:"absolute", top:0, left:0}} onClick={ (e) =>{e.stopPropagation(); setSelectedKPI(null) }}><CloseFullscreen/></IconButton>
                            </Box>
                            <Box display={"flex"} height={"90%"} width={"100%"} flexGrow={1}>
                                <KapionLineChart nodes={[props.id]} data={kpIData} kpis={[selectedKPI]}/>
                            </Box>


                        </Box>
                        :

                        <Box key={"NEW-UI"} width={"100%"} height={"100%"} gap="0.5rem" display={"flex"}
                             justifyContent={"center"} flexWrap={"wrap"} flexBasis={"100%"} alignContent={"flex-start"}
                             flexDirection={"row"} overflow={"scroll"} paddingBottom={"0.5rem"}>

                            <Tabs component={Paper} textColor={"secondary"} indicatorColor={"secondary"}
                                  sx={{width: "100%"}}
                                  value={selectedTab} onChange={(event, newValue) => {
                                setSelectedTab(newValue)
                            }}>
                                <Tab label={t("NodeDetails.StandardKPIs")} id={`simple-tab-${0}`}
                                     sx={0 === selectedTab ? null : {color: "black"}}/>
                                <Tab label={t("NodeDetails.GatewayKPIs")} id={`simple-tab-${1}`}
                                     sx={1 === selectedTab ? null : {color: "black"}}/>

                                <Tab label={t("NodeDetails.GeoInfo")} id={`simple-tab-${2}`}
                                     sx={2 === selectedTab ? null : {color: "black"}}/>
                            </Tabs>
                            {
                                (lastData) ? keyArray[selectedTab].map((key, index) => {

                                    return <KPICard key={`NEW-UI-${key}`}
                                                    kpiName={key}
                                                    label={key}
                                                    loading={kpiLoading}
                                                    latestValue={lastData[key]}
                                                    timeSeriesData={kpIData}
                                                    width={"15%"}
                                                    maxHeight={props.maxHeight}
                                                    maxWidth={props.maxWidth}
                                                    onClick={(event) => {
                                                        event?.stopPropagation();
                                                        setSelectedKPI(key)
                                                    }}
                                    />
                                }) : null}
                        </Box>
                }
            </Stack>
        </ThemeProvider>
    );
};
