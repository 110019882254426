import {LoraNode} from "../../model/lora-node/LoraNode";
import {useState} from "react";
import {useGetLastMessages} from "../custom-hooks/uiEnhancers/useGetLastMessages";
import {Box, Collapse, Divider, LinearProgress, ListItemButton, Paper, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {TTNMessage} from "../../model/lora-node/Messages/ttnMessages";
import {useTranslation} from "../MaterialOnFire/custom-hooks/useTranslation";
import {Check, Clear, HourglassDisabled, KeyboardArrowDown, KeyboardArrowUp, Mail} from "@mui/icons-material";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

interface MessageOpenInterface {
    [key: string]: boolean;
}

const prettyPrintJson = (payload: string) => {
    try {
        return (
            <pre style={{maxWidth: "100%", fontSize: 12}}>
        {" "}
                {JSON.stringify(JSON.parse(payload), null, 2)}{" "}
      </pre>
        );
    } catch (e) {
        return <pre> {payload}</pre>;
    }
};


const TimelineItem = (props: {
    isOpen: boolean;
    timestamp: string;
    payload: string;
    isNew: boolean;
    openCallback: () => void;
    item: TTNMessage;
}) => {
    const {t} = useTranslation();

    return (
        <Grid container height="100%">

            <Grid size={{ xs: 12 }} height="100%" className={props.isNew ? "newMessageHighlight" : ""}>
                <ListItemButton
                    onClick={props.openCallback}
                    sx={{height: "100%", p: 0}}
                >
                    <Grid container sx={{height: "100%"}}>
                        <Grid size={{ xs: 2 }} sx={{height: "100%"}}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="100%"
                            >
                                {props.isOpen ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 10 }}>

                            <Typography variant={"body2"}>{props.timestamp}</Typography>

                        </Grid>
                    </Grid>
                </ListItemButton>
                {getStatusIndicator(props.item)}
            </Grid>


            <Grid size={{ xs: 12 }}>
                <Collapse in={props.isOpen} timeout={"auto"} unmountOnExit={true}>
                    {props.item.type === "enqueued" ?
                        <>
                            <Typography>&nbsp; {t("NodeDetails.command")}: {props.item.instructionName}</Typography>
                            <Typography>&nbsp; {t("NodeDetails.by")}: {props.item.enqueuedBy}</Typography>
                        </>
                        : null}
                    {prettyPrintJson(props.payload)}
                </Collapse>
            </Grid>
        </Grid>
    );
};

const getIconForTypeAndStatus = (line : TTNMessage) => {
    switch (line.type){
        case "uplink" : return <ArrowCircleUpIcon color={"primary"}/>
        case "downlink" : return <ArrowCircleDownIcon color={"primary"}/>
        case "enqueued" : switch (line.status){
            case  "enqueued" : {
                let enqueuedAt = line.time.getTime();
                let now = Date.now();

                if(now-enqueuedAt >= 7.5 * 60 * 1000){
                    return <HourglassDisabled color={"warning"}/>;
                }
                return <Mail color={"primary"}/>

            }
            case "successful" : return <Check color={"secondary"}/>
            case "failed" : return <Clear color={"error"}/>
        }
    }
}

const getStatusIndicator = (line: TTNMessage) => {
    if(line.type !== "enqueued"){
        return <LinearProgress value={100} variant="determinate" color={"secondary"}/>;
    }
    switch (line.status) {
        case "enqueued":{
            let enqueuedAt = line.time.getTime();
            let now = Date.now();

            if(now-enqueuedAt >= 7.5 * 60 * 1000){
                return <LinearProgress value={100} variant="determinate" color={"warning"}/>
            }else {
                return <LinearProgress color={"secondary"}/>
            }
        }

        case "successful":
            return <LinearProgress value={100} variant="determinate" color={"secondary"}/>
        case "failed" :
            return <LinearProgress value={100} variant="determinate" color={"error"}/>
        default :
            return <LinearProgress value={100} variant="determinate" color={"secondary"}/>;
    }
}

export const MessageTimeline = (props: { selectedNode?: LoraNode }) => {
    const [messageOpen, setMessageKeyOpen] = useState<MessageOpenInterface>({});
    const lastMessages = useGetLastMessages(props.selectedNode?.ttnPayload?.ids?.dev_eui);

    return (
        <>
            <Grid
                container
                size={{ xs: 12 }}
                sx={{height: "85%", width: "100%", overflow: "auto"}}

            >
                {lastMessages.map((line, index) => {
                    return (
                        <Grid  container size={{ xs: 12 }} key={`timeLineEntry-${index}`}>
                            <Grid
                                size={{ xs: 5 }}
                                sx={{height: "100%", p: 0, minHeight: "75px"}}
                            >
                                {index % 2 === 0 ? (

                                    <Grid container
                                          display="flex"
                                          minWidth="250px"
                                          component={Paper}
                                    >
                                        <TimelineItem
                                            isOpen={messageOpen[index]}
                                            timestamp={line.time.toLocaleString("de-DE")}
                                            payload={JSON.stringify(line.payload)}
                                            isNew={line.isNew}
                                            item={line}
                                            openCallback={() => {
                                                const map = {...messageOpen};
                                                map[index] = !map[index];
                                                setMessageKeyOpen(map);
                                            }}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>


                            <Grid size={{ xs: 2 }}>
                                <Divider orientation={"vertical"} sx={{marginTop: "-10px"}}>
                                    {getIconForTypeAndStatus(line)}
                                </Divider>
                            </Grid>


                            <Grid
                                size={{ xs: 5 }}
                                sx={{height: "100%", p: 1, minHeight: "75px"}}
                                alignContent={"center"}
                                justifyContent={"center"}
                            >
                                {index % 2 !== 0 ? (
                                    <Grid container
                                          display="flex"
                                          minWidth="200px"
                                          component={Paper}
                                    >
                                        <TimelineItem
                                            isOpen={messageOpen[index]}
                                            timestamp={line.time.toLocaleString("de-DE")}
                                            payload={JSON.stringify(line.payload)}
                                            isNew={line.isNew}
                                            item={line}
                                            openCallback={() => {
                                                const map = {...messageOpen};
                                                map[index] = !map[index];
                                                setMessageKeyOpen(map);
                                            }}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};