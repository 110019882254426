import React, { useState, useEffect } from 'react';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from '@mui/lab';
import { Paper, Typography, Collapse, IconButton, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useGetKapionBackendOnboardingClient } from '../custom-hooks/kapionBackendClients/useGetKapionBackendOnboardingClient';
import { LoraNode } from '../../model/lora-node/LoraNode';

interface OnboardingViewProps {
  selectedNode: LoraNode;
}

export interface InstructionStatus {
  status: 'pending' | 'done' | 'completed' | 'failed' | 'waiting';
  timestamp: string;
  lastProcessed?: Date;
}

export interface Payload {
  bytes?: string[];
  fPort?: number;
  variables?: Record<string, any>;
  actionName?: string;
}

export interface Instruction {
  type: string;
  stepName: string;
  payload: Payload;
  status: InstructionStatus;
}

export interface OnboardingDocument {
  deviceId: string;
  status: InstructionStatus;
  dev_eui: string;
  hardwareType: string;
  hardwareTypeId: string;
  instructions: Instruction[];
  lastProcessed?: Date;
  attempts: number;
  applicationId: string;
}

const getStatusIcon = (status: string) => {
  switch (status) {
    case 'completed':
      return <CheckCircleIcon color="success" />;
    case 'pending':
    case 'waiting':
      return <HourglassTopIcon color="warning" />;
    case 'failed':
      return <ErrorIcon color="error" />;
    default:
      return <HourglassTopIcon color="info" />;
  }
};

export const OnboardingView: React.FC<OnboardingViewProps> = ({ selectedNode }) => {
  const onboardingClient = useGetKapionBackendOnboardingClient();
  const [onboardingDocument, setOnboardingDocument] = useState<OnboardingDocument | null>(null);
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => (prev === 0 ? 5 : prev - 1));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const fetchOnboardingDocument = () => {
    onboardingClient
      ?.retrieveOnboardingDocument(selectedNode.id)
      .then(setOnboardingDocument)
      .catch(console.error);
  };

  useEffect(() => {
    fetchOnboardingDocument();
  }, [selectedNode.id, onboardingClient]);

  useEffect(() => {
    if (countdown === 0) {
      fetchOnboardingDocument();
    }
  }, [countdown]);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const hasOnboardingAvailable = (node: LoraNode) => {
    return node.hardwareType && node.hardwareType.onboardingTemplate;
  };

  const createNewOnboardingDocument = () => {
    if (!selectedNode.hardwareType?.onboardingTemplate) return;

    onboardingClient
      ?.createOnboardingDocument(
        selectedNode.ttnPayload.ids.application_ids.application_id,
        selectedNode.ttnPayload.ids.device_id,
        selectedNode.ttnPayload.ids.dev_eui.replaceAll(' ', ''),
        selectedNode.hardwareType.id
      )
      .then(setOnboardingDocument)
      .catch(console.error);
  };

  const deleteOnboardingDocument = () => {
    onboardingClient
      ?.deleteOnboardingDocument(selectedNode.ttnPayload.ids.dev_eui.replaceAll(' ', ''))
      .then(() => setOnboardingDocument(null))
      .catch(console.error);
  };

  if (!onboardingDocument) {
    return (
      <>
        <Typography>No onboarding document found.</Typography>
        {hasOnboardingAvailable(selectedNode) && (
          <Button onClick={createNewOnboardingDocument}>Create new onboarding document</Button>
        )}
      </>
    );
  }

  return (
    <>
      <Typography variant="subtitle1">Aktualisierung in {countdown} Sekunden</Typography>
      <Timeline position="alternate">
        {onboardingDocument.instructions.map((instruction, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                {instruction.status.lastProcessed?.toISOString().split('T')[0] || 'N/A'}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <IconButton onClick={() => handleToggle(index)}>
                {getStatusIcon(instruction.status.status)}
              </IconButton>
              {index < onboardingDocument.instructions.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} sx={{ padding: '6px 16px' }}>
                <Typography variant="h6" onClick={() => handleToggle(index)} sx={{ cursor: 'pointer' }}>
                  {instruction.stepName} {openIndex === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Typography>
                <Collapse in={openIndex === index}>
                  <Typography>Status: {instruction.status.status}</Typography>
                  <Typography>Action: {instruction.payload.actionName || 'N/A'}</Typography>
                  <Typography>FPort: {instruction.payload.fPort || 'N/A'}</Typography>
                  <Typography>Variables: {JSON.stringify(instruction.payload.variables) || 'N/A'}</Typography>
                  <Typography>Bytes: {JSON.stringify(instruction.payload.bytes) || 'N/A'}</Typography>
                </Collapse>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <Button onClick={deleteOnboardingDocument}>Remove document</Button>
      <Button onClick={createNewOnboardingDocument}>Create new onboarding document</Button>
    </>
  );
};

