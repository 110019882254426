import {Gateway} from "../../../model/lora-node/gateway";
import {LoraNode} from "../../../model/lora-node/LoraNode";
import {useEffect, useMemo} from "react";
import {getDistance} from "geolib";
import {GatewayResponseItem} from "../gateways/useGetConnectedNodes";
import {useGetFirebaseConfigParameter} from "../../MaterialOnFire/custom-hooks/useGetFirebaseConfigParameter";
import {
    HEAT_MAP_CONFIG_PARAMETER_NAME,
    heatMapSettings
} from "../../MaterialOnFire/custom-hooks/types/firebaseRemoteConfigTypes";

import "./gatewayHeatmap.css"
import L from "leaflet";

interface SignalStrength {
    gateway: Gateway,
    node: LoraNode
    radius: number,
    rssi: number
    snr: number
}

const getRssiAndSnrFromConnectedNode = ( gateway:Gateway, connectedNode: GatewayResponseItem) => {
    const keys = Object.keys(connectedNode)
    for( let key of keys) {

        if(!key.match(/^Gateway-[0-9]+-Id$/)) {
            continue;
        }

        const value = connectedNode[key]
        if( value === gateway.id) {
            return {
                rssi: Number.parseFloat(connectedNode[key.replace("Id", "RSSI")] as string),
                snr: Number.parseFloat(connectedNode[key.replace("Id", "SNR")] as string)
            }
        }
    }

    return {
        rssi: -9999,
        snr: -9999
    }


}

export const useGetGatewayHeatMap = (gateway: Gateway|null, loraNodes:LoraNode[], connectedNodesList: GatewayResponseItem[]) => {
    const heatMapSettings = useGetFirebaseConfigParameter<heatMapSettings>( HEAT_MAP_CONFIG_PARAMETER_NAME)

    const gatewayHeatMapClusterGroup = useMemo(() => {



        const ClusterGroup = L.markerClusterGroup({
            chunkedLoading: true,
            showCoverageOnHover: true,
            zoomToBoundsOnClick: true,
            disableClusteringAtZoom: 10,
            maxClusterRadius: (zoom) => {
                return (19 / zoom) * 20;
            },
        });
        return ClusterGroup
    },[])


    useEffect(() => {


        if (!gateway || !loraNodes || !connectedNodesList) {
            gatewayHeatMapClusterGroup.clearLayers()
            return
        }

        const signalStrengths: SignalStrength[] = []
        connectedNodesList.forEach((connectedNode) => {
            const node = loraNodes.find((node: LoraNode) => node.id === connectedNode.nodeId)
            if (!node) {
                return
            }

            const distance = getDistance(gateway.location, node.location)

            const {rssi, snr} = getRssiAndSnrFromConnectedNode(gateway, connectedNode)

            signalStrengths.push({
                gateway: gateway,
                node: node,
                radius: distance,
                rssi: rssi,
                snr: snr
            })
        })

        //sort signal streng by distance descinding
        signalStrengths.sort((a, b) =>  b.radius -a.radius )

        // the rssi can range from -30(good) to -120(bad) create a seamless gradient from green to red over yellow for the rssi
        // the snr can range from 0(good) to -20(bad) translate a color from green to red over yellow for the snr
        const rssiToColor = (rssi: number) => {
            const minRssi = heatMapSettings?.badRssi || -120;
            const maxRssi = heatMapSettings?.goodRssi || -30;
            const ratio = (rssi - minRssi) / (maxRssi - minRssi);
            const r = Math.min(255, Math.max(0, Math.floor(255 * (1 - ratio))));
            const g = Math.min(255, Math.max(0, Math.floor(255 * ratio)));
            return `rgb(${r},${g},0)`;
        }

        signalStrengths.forEach((signalStrength) => {
            const circle =  L.circle(signalStrength.gateway.location, {
                className: "heatmapCircle",
                radius: signalStrength.radius,
                color: rssiToColor(signalStrength.rssi),
                fillColor: rssiToColor(signalStrength.rssi),
            })
                circle.bindPopup(`<b>${signalStrength.node.name}</b><br> RSSI: ${signalStrength.rssi} SNR: ${signalStrength.snr} <br> Distanz: ${signalStrength.radius}m`)

                circle.addEventListener({
                    mouseover: (event) => {
                        circle.openPopup()
                    },
                    mouseout: (event) => {
                        circle.closePopup()
                    }
                })
                circle.addTo(gatewayHeatMapClusterGroup)
        })


     }, [gatewayHeatMapClusterGroup,gateway, loraNodes, connectedNodesList]);

return gatewayHeatMapClusterGroup;

}