import {LoraNode} from "../../../model/lora-node/LoraNode";
import {useSelector} from "react-redux";
import {KPIDocument} from "../NodeDetails";
import {MinKPIMapping} from "../useGetDeviceTimeSeries";
import {useEffect, useState} from "react";


export interface OverviewKPIMapItem {

    kpiName: string;
    kpiUnit: string;

}

export interface OverviewKPIMap {
    [key: string]: OverviewKPIMapItem

}

export const useGetOverviewKPIDisplayMap = (loraNodes : LoraNode[]) => {

    const kpiMappings = useSelector((state: any) => state["KPIMapping"]?.items as MinKPIMapping[]  )
    const  [overViewKPIMap, setOverviewKPIMap] = useState<OverviewKPIMap>({})
    useEffect(() => {
        const newMap: OverviewKPIMap = {}
        loraNodes.forEach( (node) => {
            const mapping = kpiMappings.find( (mapping) => mapping.hardwareType.id === ( node.hardwareType?.id || "") )
            if (mapping) {
                mapping.mappings.forEach( (map) => {
                    if (map.displayOnOverview) {
                        newMap[node.id] = {
                            kpiName: map.name,
                            kpiUnit: map.unitFrom
                        }
                    }
                })
            }
        })

        setOverviewKPIMap(newMap)
    }, [loraNodes, kpiMappings]);

    return overViewKPIMap;

}