import { UiDescriptor } from "../MaintenanceView";
import {
  Checkbox,
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ReactNode, useEffect, useState } from "react";
import { GenericTablePopup } from "./GenericTablePopup";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { GenericTokenizer } from "../GenericTokenizer/GenericTokenizer";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useGetIconList } from "../../custom-hooks/useGetIconList";
import { useTranslation } from "../../custom-hooks/useTranslation";
import { GenericCookieSlider } from "../../GenericUIViews/GenericCookieSlider";
import React from "react";

const HeaderFromUiDescriptor = (props: {
  uiDescriptorTable: UiDescriptor[];
  translationPrefix: string;
}) => {
  const { t } = useTranslation();
  return (
    <>
      {props.uiDescriptorTable.map((uiDescriptor) => (
        <React.Fragment key={uiDescriptor.fieldName + "_headerFragment"}>
          {uiDescriptor?.childUIDescriptor && uiDescriptor.visible ? (
            <HeaderFromUiDescriptor
              key={uiDescriptor.fieldName + "_nestedHeader"} // FIX 1: Added missing key
              translationPrefix={props.translationPrefix}
              uiDescriptorTable={uiDescriptor.childUIDescriptor}
            />
          ) : uiDescriptor.visible ? (
            <TableCell key={uiDescriptor.fieldName + "_cell"}>
              {t(`${props.translationPrefix}.${uiDescriptor.fieldName}`)}
            </TableCell>
          ) : null}
        </React.Fragment>
      ))}
    </>
  );
};

const RowCellsFromData = (props: {
  data: any;
  translationPrefix: string;
  uiDescriptorTable: UiDescriptor[];
}) => {
  const iconList = useGetIconList();
  return (
    <>
      {props.uiDescriptorTable.map((uiDescriptor) => {
        let renderValue: ReactNode = <></>;

        switch (uiDescriptor.fieldType) {
          case "tokenizer":
            renderValue = (
              <GenericTokenizer
                key={uiDescriptor.fieldName + "_tokenizer"}
                translationPrefix={props.translationPrefix}
                disabled={true}
                onChange={() => {}}
                fieldKey={uiDescriptor.fieldName}
                value={props.data[uiDescriptor.fieldName]}
                uiDescriptor={uiDescriptor}
              />
            );
            break;
          case "checkbox":
            renderValue = (
              <Checkbox
                key={uiDescriptor.fieldName + "_checkbox"}
                checked={props.data[uiDescriptor.fieldName] || false}
              />
            );
            break;
          case "table":
            renderValue = "";
            break;
          case "icon":
            renderValue = iconList.getIcon(props.data[uiDescriptor.fieldName]);
            break;
          case "selectField":
            renderValue = Object.keys(props.data[uiDescriptor.fieldName] || {}).length > 1
              ? ""
              : props.data[uiDescriptor.fieldName];
            break;
          default:
            renderValue = props.data[uiDescriptor.fieldName] || "";
            break;
        }

        return (
          <React.Fragment key={uiDescriptor.fieldName + "_rowFragment"}>
            {uiDescriptor.childUIDescriptor && uiDescriptor.visible ? (
              <RowCellsFromData
                key={uiDescriptor.fieldName + "_nestedRowCells"} // FIX 2: Added missing key
                translationPrefix={props.translationPrefix}
                data={props.data[uiDescriptor.fieldName] || {}}
                uiDescriptorTable={uiDescriptor.childUIDescriptor}
              />
            ) : uiDescriptor.visible ? (
              <TableCell key={uiDescriptor.fieldName + "_tableCell"} sx={{ maxHeight: "20px" }}>
                {renderValue}
              </TableCell>
            ) : null}
          </React.Fragment>
        );
      })}
    </>
  );
};

export const GenericTable = (props: {
  table: any[];
  uiDescriptorTable: UiDescriptor[];
  translationPrefix: string;
  updateTable: (table: any[]) => void;
  overflows?: boolean;
}) => {
  const [localTable, setLocalTable] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState<any[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState<any>(null);
  const [dialogIndex, setDialogIndex] = useState<number | null>(null);

  useEffect(() => {
    setLocalTable(props.table);
  }, [props.table]);

  useEffect(() => {
    let copy = [...localTable];
    setRows(copy.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage));
  }, [rowsPerPage, page, localTable]);

  return (
    <Grid key="tableContainerGrid" container sx={{ maxHeight: "75vh", overflow: "hidden", width: "100%" }}>
      <TableContainer key="tableContainer" sx={{ maxHeight: "69vh", minHeight: "100%", maxWidth: "100%", paddingBottom: "5vh" }}>
        <Table key="Table" stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell key="addButtonCell">
                <IconButton
                  onClick={() => {
                    setDialogOpen(true);
                    setDialogData({ id: crypto.randomUUID() });
                  }}
                >
                  <AddCircleIcon />
                </IconButton>
              </TableCell>
              <HeaderFromUiDescriptor translationPrefix={props.translationPrefix} uiDescriptorTable={props.uiDescriptorTable} />
            </TableRow>
          </TableHead>

          <TableBody sx={{ overflow: "hidden" }}>
            {rows.map((line, index) => (
              <TableRow key={"row_" + index}>
                <TableCell key={"actions_" + index} >
                  <Grid container  minWidth={"6rem"} alignItems="center" >
                    <Grid  size={{xs:4}} >
                      <Stack direction="column">
                        <IconButton
                          key={"up_" + index}
                          disabled={index === 0}
                          onClick={() => {
                            let copy = [...localTable];
                            copy.splice(index - 1, 0, copy[index]);
                            copy.splice(index + 1, 1);
                            setLocalTable(copy);
                            props.updateTable(copy);
                          }}
                        >
                          <KeyboardArrowUpIcon />
                        </IconButton>
                        <IconButton
                          key={"down_" + index}
                          disabled={index === localTable.length - 1}
                          onClick={() => {
                            let copy = [...localTable];
                            copy.splice(index + 2, 0, copy[index]);
                            copy.splice(index, 1);
                            setLocalTable(copy);
                            props.updateTable(copy);
                          }}
                        >
                          <KeyboardArrowDown />
                        </IconButton>
                      </Stack>
                    </Grid>
                    <Grid size={{xs:4}}>
                      <IconButton
                        onClick={() => {
                          setDialogData(line);
                          setDialogOpen(true);
                          setDialogIndex(index)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>

                    <Grid size={{xs:4}}>
                      <IconButton
                        onClick={() => {
                          let copy = [...localTable];
                          copy.splice(index, 1);
                          setLocalTable(copy);
                          props.updateTable(copy);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </TableCell>
                <RowCellsFromData translationPrefix={props.translationPrefix} data={line} uiDescriptorTable={props.uiDescriptorTable} />
              </TableRow>
          ))}
        </TableBody>
      </Table>
      {dialogOpen ? (
        <GenericTablePopup
          key={dialogData?.id || "Dialog"}
          translationPrefix={props.translationPrefix}
          data={dialogData}
          open={dialogOpen}
          lineIndex={dialogIndex||0}
          uiDescriptorTable={props.uiDescriptorTable}
          handleOkay={(data) => {
            let index = localTable.findIndex((value) => {
              if (value?.id) {
                return value?.id === data?.id && value?.id;
              } else {
                //UI-Descriptors don't have an id field
                return value?.fieldName === data?.fieldName && value?.fieldName;
              }
            });

            let copy = [...localTable];
            if (index > -1) {
              copy[index] = data;
            } else {
              copy.push(data);
            }
            setLocalTable(copy);
            setDialogOpen(false);
            props.updateTable(copy);
          }}
          handleClose={() => setDialogOpen(false)}
        />
      ) : (
        <></>
      )}
    </TableContainer>
      <Grid container sx={{width:"100%"}} position={"sticky"} left={0} bottom={0} spacing={1} bgcolor={ theme => theme.palette.background.paper} >
        <Grid size={{xs:4}}>
          <GenericCookieSlider identifier={"GenericTableSlider"} initialValue={rowsPerPage} handleChange={ (newValue) => {setRowsPerPage(newValue)
            setPage(1)
          }} />
        </Grid>
        <Grid size={{xs:8}}>
          <Pagination size={"small"} sx={{width:"fit-content"}} page={page} defaultPage={1} onChange={(event: React.ChangeEvent<unknown>, value: number) => {
            setPage(value) }}count={Math.ceil(localTable.length / rowsPerPage )} color="primary" />
        </Grid>
      </Grid>
      </Grid>
  );
}