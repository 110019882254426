import {UiEnhancements} from "../../MaterialOnFire/custom-hooks/useGetRoutes";
import {useSnackbar} from "notistack";
import {useGetKapionBackendClient} from "../kapionBackendClients/useGetKapionBackendClient";
import {GenericActionBarAction} from "../../MaterialOnFire/GenericUIFields/GenericActionBar";
import {HardwareType} from "../../../model/hardware-type/hardwareType";
import {FeedbackSnackbar} from "../../FeedbackSnackbar";


export const useGetKPIMappingUIEnhancer = () => {

    const {enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {backendKPIClient} = useGetKapionBackendClient();


    const kpiMappingUIActionBarEnhancer = (
        item: any,
        actions: GenericActionBarAction[]
    ) => {
        const originalSaveAction = actions[0].action;
        actions[0].action= async ( newState:{ hardwareType: {id : string}}, uiDefinition ) =>{
            await originalSaveAction(newState, uiDefinition);

            const key=Date.now();
            enqueueSnackbar(<FeedbackSnackbar  closeSnackbar={closeSnackbar} promise={ backendKPIClient.updateHardwareTypeKPIIndex(newState.hardwareType.id)}
                                               componentIndicator={"upsertDeviceKPIIndex"}
                                               snackbarKey={key}/>, {variant: "default", persist: true, key: key})
        }
        return actions;
    }

    const kpiMappingUiEnhancer = {
        enhanceActionBar: kpiMappingUIActionBarEnhancer,
    } as UiEnhancements;

    return { key: "KPIMapping", enhancer: kpiMappingUiEnhancer };
}