import {Box, Paper, Tooltip, Typography} from "@mui/material";
import {useMaterialOnFireNavigate} from "../../MaterialOnFire/custom-hooks/useMaterialOnFireNavigate";
import {LoraNode} from "../../../model/lora-node/LoraNode";
import React, {useMemo, useState} from "react";
import {NodeModal} from "../NodeModal/NodeModal";
import "./NodePage.css"
import {TimeSeriesEntry} from "../../custom-hooks/uiEnhancers/Dashboards/types/type";
import {OverviewKPIMapItem} from "./useGetOverviewKPIDisplayMap";
import {
    SignalCellular0BarOutlined,
    SignalCellular1BarOutlined,
    SignalCellular2BarOutlined,
    SignalCellular3BarOutlined,
    SignalCellular4BarOutlined
} from "@mui/icons-material";


const SignalIconByStrength = ( props :{strength: number}) => {

    return (props.strength > -60) ?
        <SignalCellular4BarOutlined color={"secondary"}/> :
        (props.strength > -80) ? <SignalCellular3BarOutlined color={"secondary"}/> :
            (props.strength > -100) ? <SignalCellular2BarOutlined color={"warning"}/> :
                (props.strength > -120) ? <SignalCellular1BarOutlined color={"warning"}/>
                    : <SignalCellular0BarOutlined color={"error"}/>



}

export function NodePageNodeCard(props: {
    node: LoraNode,
    loading: boolean,
    kpiToDisplay?: OverviewKPIMapItem,
    value?: TimeSeriesEntry
}) {

    const [modalOpen, setModalOpen] = useState(false)
    const signalStrength = useMemo( () => {

        let RSSI = -9999
        if(!props.value || props.loading){
            return RSSI
        }

        Object.keys(props.value).filter( key => key.includes("RSSI")).forEach( (key) => {
            //@ts-ignore
            const value = props.value[key]
            if(value > RSSI){
                RSSI = parseFloat( `${value}` )
            }
        })


        return RSSI;

    },[props.loading, props.value])


    return <Box position={"relative"} sx={{cursor: "pointer"}} key={props.node.id} display="flex"
                flexDirection={"column"} component={Paper}
                width={"12rem"} height={"8rem"} onClick={() => {
        setModalOpen(true)
    }}>
        <Box key={"header"} display={"flex"} flexDirection={"row"} width={"100%"} justifyContent={"center"} alignItems={"center"}>
            <Box display={"flex"} flexGrow={1} maxWidth={"100%"} overflow={"hidden"}>
                <Tooltip title={props.node.name}>
                <Typography key={"header-typography"} noWrap={true} align={"center"} width={"100%"}>{props.node.name}</Typography>
                </Tooltip>
            </Box>
            {(!props.loading) ? <Tooltip title={props.value?.time ? new Date(props.value.time).toString() : "-" }><Box   ><SignalIconByStrength strength={signalStrength} /> </Box> </Tooltip>: <SignalCellular4BarOutlined color={"primary"}/>}


        </Box>
        <Box key={"kpi-box"} display={"flex"} flexGrow={1} justifyContent={"center"} alignItems={"center"}>

            <Typography key={"kpi-value"} variant={"h4"}
                        color={"secondary"}
                        className={props.loading ? "TypographySkeletonAnimation" : ""}

            >{props.loading ? (Math.random() * 100).toFixed(2) : (props.value && props.kpiToDisplay) ? `${props.value[props.kpiToDisplay.kpiName] || "-"} ${props.kpiToDisplay.kpiUnit}` : ""}</Typography>

        </Box>
        <NodeModal key={`${props.node.id}-modal`} loraNode={props.node} open={modalOpen} onClose={() => {
            setModalOpen(false);
        }}/>

        {


        }
    </Box>

}