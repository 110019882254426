import {Box, Card, Typography} from "@mui/material";
import {Cell, Pie, PieChart, ResponsiveContainer} from "recharts";
import {useEffect, useRef, useState} from "react";
import {useGetTheme} from "../../custom-hooks/useGetTheme";

interface KPICardProps{
    width: any,
    label: string,
    data: { positive : number,
        negative : number},
}


export const AnalyticsPieChart = ( props:KPICardProps) =>{

    const [size, setSize] = useState({height: 0, width:0})

    const ref = useRef(null)

    const theme = useGetTheme()
    useEffect(() => {

        if (ref) {
            //@ts-ignore
            setSize({width: ref?.current?.clientWidth, height: ref?.current?.clientHeight})
        }
    },[ref])

    const [percent, setPercent] = useState(0);
    useEffect(() => {
        setPercent(props.data.positive / ( props.data.positive + props.data.negative)*100 ||0)
    }, [props.data]);
    return <Card ref={ref} key="KPICard"sx={ {width : props.width, height:"100%" , padding:"0.5rem" , maxHeight:"100%"}} >
        <Box width={"100%"} height={"20%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <div>
                <Typography fontWeight={"bold"} fontSize={"2rem"}> {props.label} </Typography>
            </div>
        </Box>
        <Box height={"80%"} width={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"flex-start"}>

            <ResponsiveContainer height={"100%"} width={"100%"}>
                <PieChart key="KPI-Chart"   >
                    <Pie  paddingAngle={0.5} data={[ { name: "Aktive Nodes", value: props.data.positive} , {name:"Inaktive Nodes",  value: props.data.negative} ]} dataKey="value" cx="50%" cy="50%"  innerRadius={size.height*0.3} outerRadius={size.height*0.35} fill="#8884d8" >
                        {[ { name: "Aktive Nodes", value: props.data.positive} , {name:"Inaktive Nodes",  value: props.data.negative} ]
                            .map( (entry, index) => <Cell fill={ index === 0 ? theme.palette.secondary.main : theme.palette.error.main } />)}
                    </Pie>

                </PieChart>
            </ResponsiveContainer>

            <Box position={"absolute"} alignSelf={"center"} justifySelf={"center"}>
                <Typography fontSize={"2rem"} color={ percent > 95 ? "secondary" : percent > 60 ? "warning" : "error"}>{(percent).toFixed(2)}%</Typography>
            </Box>
        </Box>

    </Card>
}