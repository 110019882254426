import {DiagramList} from "./DiagramList";
import React from "react";
import "./DiagramPreview.css"
import {DiagramProperties} from "../../types/type";


export const DiagramPreview = (props: {dashboard: any, onDrag:(e:any) => void ,  dashboardIdList:string[], onDragEnd:(x: number, y: number)=>void , setDragEnabled: (dashboardName: string, dragging: boolean, origin:"preview" | "dashboard",
                                                                                                                                          diagramProperties: DiagramProperties | null, position: {     mouseX: number;     mouseY: number;     diagramX: number;     diagramY: number; }) => void } ,  ) => {

 return <div className={"infoarea"}>

    {

        <DiagramList {...props} />



    }
 </div>}