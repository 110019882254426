import * as React from "react";
import { IMaskInput } from "react-imask";
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
export const EightHexBitTextMask = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref: any) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={"## ## ## ##"}
        placeholderChar={"#"}
        lazy={false}
        definitions={{
          "#": /[0-9A-Fa-f]/,
        }}
        inputRef={ref}
        onAccept={(value) =>
          onChange({ target: { name: props?.name, value: value as string } })
        }
        overwrite
      />
    );
  }
);
