import { useMap } from "react-leaflet";
import React, { useEffect } from "react";
import * as L from "leaflet";
import { divIcon } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import "./PlaceDeviceMapHandler.css";

export const PlaceDeviceMapHandler = (props: {
  browserLocation: { lat: number; lng: number };
  deviceLocation: { lat: number; lng: number };
  updateDeviceLocation: (lat: number, lng: number) => void;
}) => {
  const map = useMap();

  useEffect(() => {
    let personMarker = L.marker(
      new L.LatLng(props.browserLocation.lat, props.browserLocation.lng),
      {
        icon: divIcon({
          html: renderToStaticMarkup(<div className={`PersonDot`}></div>),
          className: "",
        }),
      }
    );

    let marker = L.marker(
      new L.LatLng(props.deviceLocation.lat, props.deviceLocation.lng),
      {
        draggable: true,
        icon: divIcon({
          html: renderToStaticMarkup(<div className={`marker`}></div>),
          className: "",
        }),
      }
    );

    marker.addEventListener("dragend", (event) => {
      let lat = event.target.getLatLng().lat;
      let lng = event.target.getLatLng().lng;
      props.updateDeviceLocation(lat, lng);
    });
    map.addLayer(marker);
    map.addLayer(personMarker);
    return () => {
      map.removeLayer(marker);
      map.removeLayer(personMarker);
    };
  }, [props.deviceLocation.lat, props.deviceLocation.lng, props.browserLocation.lat, props.browserLocation.lng, map, props.updateDeviceLocation, props]);

  return <div></div>;
};
