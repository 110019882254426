import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import { setDrawerOpen, setSearchString } from "./appBarState";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircle from "@mui/icons-material/AccountCircle";
import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { drawerWidth } from "./drawer/Drawer";
import { useGetNewTTNCount } from "../../custom-hooks/useGetNewTTNCount";

import { StyledMenu } from "./StyledMenu";
import { useTranslation } from "../custom-hooks/useTranslation";
import {Autocomplete, TextField} from "@mui/material";
import {SearchComponent} from "./SearchComponent";
import { useMaterialOnFireNavigate } from "../custom-hooks/useMaterialOnFireNavigate";
import {useGetAlerts} from "../custom-hooks/useGetAlerts";
import {AlertList} from "./AlertList";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function StyledAppBar() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [alertsOpen, setAlertsOpen] = React.useState<boolean>(false);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-search-account-menu";
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const {navigate} = useMaterialOnFireNavigate();

  const alerts = useGetAlerts();

  const drawerOpen = useSelector((state: RootState) => state.appBar.drawerOpen);
  const { t } = useTranslation();
  return (
    <AppBar key="appbar" sx={{backgroundColor:"black"}} position="absolute" open={drawerOpen}>
      <AlertList key={"alerts"} toggled={alertsOpen} alerts={alerts} setClose={()=>setAlertsOpen(false)}/>
      <Toolbar color={"black"}>
        <IconButton
          size="large"
          edge="start"
          color="primary"
          onClick={() => {
            dispatch(setDrawerOpen(true));
          }}
          aria-label="open drawer"
          sx={{ mr: 2, ...(drawerOpen && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>

          <img  onClick={ () => {navigate("..","","")}} src={"/logo.png"} style={{ width: "200px" ,  marginRight:"2rem"}} />

          <SearchComponent/>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: "flex" } }}>
          <IconButton
            size="large"
            color="inherit"
            onClick={()=>{
              setAlertsOpen(true)}}
          >
            <Badge badgeContent={alerts.length} color="error" >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Box>
      </Toolbar>
      <StyledMenu
        anchorEl={anchorEl}
        menuId={menuId}
        isMenuOpen={isMenuOpen}
        handleMenuClose={handleMenuClose}
      />
    </AppBar>
  );
}
