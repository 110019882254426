import {
    DynamicOnboardingTemplateInstruction,
    DynamicPayload,
    isDynamicOnboardingTemplateInstruction, isDynamicPayload
} from "./OnboardingTemplateTypes";
import {useMemo, useState} from "react";
import {Box, TextField} from "@mui/material";

export const DynamicInstructionUi = ( props:{ instruction: any , updateInstruction: ( newInstruction : DynamicOnboardingTemplateInstruction) => void }) => {

    const localPayload = useMemo<DynamicPayload>(() => {
       return isDynamicPayload(props.instruction.payload) ? props.instruction.payload : {
            payload: {
                variables: {},
                actionName: "New Action"
            }
        }
    }, [props.instruction]);

    return <Box gap={"0.25rem"} display={"flex"}>
            <TextField key={"actionName"} label={"Action Name"} value={localPayload.actionName} onChange={(event) => {
                props.updateInstruction({...props.instruction, payload: {...localPayload, actionName: event.target.value}})
            }}/>



        </Box>

}