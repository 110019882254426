import React, {useCallback} from "react";
import {DragStatus} from "../DiagramContainer";
import {DashboardDiagramConfig, DiagramProperties} from "../types/type";

export function useGetDnDHandleFunctions(
    setDragStatus: (prevState: DragStatus) => void, ref: React.MutableRefObject<any>
    , dragStatus: DragStatus
    , columns: number,
    columnRef: React.MutableRefObject<{ x: number; y: number }>,
    setPosDisplay: (value: (((prevState: {
    x: number;
    y: number
}) => { x: number; y: number }) | {
    x: number;
    y: number
})) => void, updateCoords: (name: string, x: number, y: number, sizeX?:number, sizeY?:number) => void,
    addDiagramToDashboard: (data: DashboardDiagramConfig) => void

) {
    const setDragStatusCallback = useCallback((dashboardName: string,
                                               dragging: boolean,
                                               origin:"preview" | "dashboard",
                                               diagramProperties: DiagramProperties | null,
                                               position: {
                                                   mouseX: number,
                                                   mouseY: number,
                                                   diagramX: number,
                                                   diagramY: number
                                               }) => {

        setDragStatus({dashboardName, dragging, position, origin, diagramProperties})
    }, [setDragStatus])


    const onDrag = useCallback((e: any) => {
        const rect = ref.current.getBoundingClientRect()
        const movedX = e.clientX - dragStatus.position.mouseX
        const movedY = e.clientY - dragStatus.position.mouseY
        const newPosX = dragStatus.position.diagramX + movedX - rect.left
        const newPosY = dragStatus.position.diagramY + movedY - rect.top
        const column = Math.floor(newPosX / (rect.width / columns)) + 1
        const row = Math.floor(newPosY / (rect.height / columns)) + 1
        columnRef.current = {x: column, y: row}
        setPosDisplay({x: column, y: row})

        //console.log("onDrag", e.clientX, e.clientY, dragStatus.position.mouseX, dragStatus.position.mouseY, newPosX, newPosY, column, row, rect.left, rect.top, rect.width, rect.height, columns, ref.current.getBoundingClientRect())
    }, [dragStatus.position, columns, ref]);


    const onDragEnd = useCallback((x: number, y: number, xBottom?:number, yBottom?:number) => {
        setDragStatus({...dragStatus, dragging: false})
        const rect = ref.current.getBoundingClientRect()
        const column = Math.floor((x - rect.left) / (rect.width / columns)) + 1
        const row = Math.floor((y - rect.top) / (rect.height / columns)) + 1



        if(dragStatus.origin === "preview" && dragStatus.diagramProperties){
            addDiagramToDashboard({
                DiagramDefinition: dragStatus.diagramProperties ,
                posX: column,
                posY:row,
                sizeX:5,
                sizeY:5,
            } as DashboardDiagramConfig)
        }
        if(dragStatus.origin === "dashboard") {
            updateCoords(dragStatus.dashboardName, column, row)
            if(xBottom && yBottom){

                const columnBottom = Math.floor((xBottom - rect.left) / (rect.width / columns)) + 1
                const rowBottom = Math.floor((yBottom - rect.top) / (rect.height / columns)) + 1

                updateCoords(dragStatus.dashboardName, column  , row   ,  columnBottom  - column, rowBottom  - row )
            }
        }

    }, [dragStatus, columns, ref]);
    return {setDragStatusCallback, onDrag, onDragEnd};
}