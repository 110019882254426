import {DiagramProps} from "./useGetDiagramType";
import {TimeSeriesEntry} from "../../types/type";
import {useEffect, useState} from "react";
import {transformToRechartsSeries} from "./RechartsHelper";
import {Box, Paper, Tooltip, Typography} from "@mui/material";
import "./kpiCard.css"
import {ColorPalette} from "./ColorPalette";


export const KapionKPIChart = (props:DiagramProps) => {
    const [timeSeries, setTimeSeries] = useState<TimeSeriesEntry|null>(null)
    const [nodes, setNodes] = useState<string[]>([])

    useEffect(() => {

            const [series, nodes ] = transformToRechartsSeries(props);
            setTimeSeries( series?.length > 0?series?.reduce( ( previousValue, currentValue, currentIndex, array)=>{
                return {...previousValue, ...currentValue, [currentValue.nodeId+"-time"]: currentValue.time, [previousValue.nodeId+"-time"]: previousValue.time}
            }) : null)

            setNodes(nodes)
    }, [props]);
    return  <Box display={"flex"}  flexGrow={1} flexDirection={"row"} position={"relative"} gap={"0.5rem"} alignContent={"flex-start"} justifyContent={"center"} margin="0.2rem" overflow={"auto"} height={"100%"} width={"100%"} >
        <Box display={"flex"}   flexDirection={"row"}  flexWrap={"wrap"} gap={"0.5rem"} alignContent={"flex-start"} justifyContent={"center"}   overflow={"auto"} flexGrow={1} width={"fit-content"}>

        {
           nodes.map( (node, index) => {

                    if (!timeSeries) {
                        return <></>

                    }
                    const key = `${node}-${props.kpis[0]}`
                    return <Box key={node}  sx={{ padding:0,
                                                   margin:0,
                                                //@ts-ignore
                                                    backgroundColor: ColorPalette[`color${index %6}`].color,
                                                   alignContent:"flex-start",
                                                   justifyContent:"center",
                                                   display:"flex",
                                                   flexDirection:"column",
                                                   width:"10rem" ,
                                                   height: "7.5rem",}}>
                        <Box key={"headline"} height="10%"   margin={"0.5rem"}>
                            <Tooltip title={node} >
                            <Typography noWrap={true} align={"center"} >
                                {node}
                            </Typography>
                            </Tooltip>
                        </Box>

                        <Box key={"kpi"} flexGrow={1}  alignContent={"center"} justifyContent={"center"}>
                            <Tooltip title={ new Date(timeSeries[node+"-time"]).toLocaleString()}  >
                            <Typography align={"center"} variant={"h3"} noWrap={true} color={"white"}>
                                {timeSeries[key]}
                            </Typography>
                            </Tooltip>
                        </Box>

                    </Box>
                })

        }
        </Box>
        
    </Box>
}