import {Box} from "@mui/material";


export function DnDOverlay(props: {
    dragStatus: {
        dragging: boolean;
        position: { mouseX: number; mouseY: number; diagramY: number; diagramX: number };
        dashboardName: string
    }, columns: number, posDisplay: { x: number; y: number }
}) {
    return props.dragStatus.dragging ? <>{Array.from({length: props.columns}, (v, i) => i).map((v, i) =>
        Array.from({length: props.columns}, (v, j) => {
            return <Box zIndex={0} key={`${i}-${j}`} sx={{
                gridColumnStart: j + 1,
                gridRowStart: i,
                gridColumnEnd: j + 2,
                gridRowEnd: i,
                bgcolor: (i === props.posDisplay.y && j + 1 === props.posDisplay.x) ? "lightgreen" : "darkgrey",
                margin: "0.5rem"
            }}/>

        })).flat()}</> : <></>;
}