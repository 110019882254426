import Grid from "@mui/material/Grid2";
import { useGetRoutes } from "../custom-hooks/useGetRoutes";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {useEffect, useState} from "react";
import { setActiveComponent, setHit, setHitlist, setSearchString } from "../app-bar/appBarState";
import { useMaterialOnFireNavigate } from "../custom-hooks/useMaterialOnFireNavigate";
import {LandingPageAnalytics} from "./LandingPageAnalytics";
export default () => {

  const { getRoutes } = useGetRoutes();
  const reduxState = useSelector( (state:RootState) => state)
  const hit = useSelector( (state:RootState) => state.appBar.hit)
  const dispatch = useDispatch()
  const {navigate} = useMaterialOnFireNavigate()

  

  //todo implement feature switch
  const [iotAnalyticsEnabled] = useState(false);
  useEffect( () => {

    dispatch(setHitlist( reduxState.uiDefinitions.uiDefinitions.map( definition => {
      // @ts-ignore

      const list =  reduxState[ definition.model]?.items.map( item => {   return { label : `${definition.name}: ${item?.name} `, id: `${definition.model}/${item.id}` } })
      if(definition.name === "Lora Node"){
        //@ts-ignore
        list.push( ...reduxState[ definition.model]?.items.map( item => { return { label: `Map: ${item?.name}` ,id: `map?lat=${item?.location?.lat}&lng=${item?.location?.lng}&zoomLevel=18`}} ))

      }

      list.push( { label: definition.name, id:`${definition.model}/$`})
      return list
      } ).flat(1).sort( (a , b) => { return  a.label.localeCompare( b.label)  })))
    dispatch(setActiveComponent("LandingPage"))
  } ,[])

  useEffect( () => {
    if(hit){
      navigate("",hit.id,"");
      dispatch(setHit(null))
      dispatch(setSearchString(""))
    }
  },[hit])
  return (
    <Grid
      container
      justifyContent={"flex-start"}
      alignItems={"center"}
      spacing={4}
      sx={{ width: "100%", height: "flex-grow" }}
    >
      {iotAnalyticsEnabled?  <Grid size={{ xs: 12 }}> <LandingPageAnalytics/> </Grid> : null}
      {getRoutes().map((route) => {
        return (
          <Grid
              key={`landingPageRouteKey-${route.name}`}
              size={{ xs: 12, sm: 5, md:4, xl:2 }}  sx={{ height: "flexWrap" }}>
            {route.landingPageTile}
          </Grid>
        );
      })}
    </Grid>
  );
};
