import {CircularProgress, Stack, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {Cancel, CheckCircle} from "@mui/icons-material";
import {useTranslation} from "./MaterialOnFire/custom-hooks/useTranslation";

export const FeedbackSnackbar = (props: {
    promise: any,
    closeSnackbar : (key: any) => void,
    snackbarKey: number
    componentIndicator? : string
    successCallback? : () => void
    failureCallback? : () => void
    finallyCallback? : () => void
}) => {
    const [errorMessage, setErrorMessage] = useState<string|null>(null);
    const [errorDetails, setErrorDetails] = useState<any|null>(null);
    const [state, setState] = useState<"Success"|"Error"|"Executing">("Executing")
    const {t} = useTranslation();

    useEffect(() => {
        props.promise
            .then(() => {
                setState("Success")
                if(props.successCallback){
                    props.successCallback();
                }
            })
            .catch((e : any) => {
                setState("Error")
                setErrorMessage(e.message)
                setErrorDetails(e.details)
                if(props.failureCallback){
                    props.failureCallback();
                }
            })
            .finally(() => {
               setTimeout(() => {props.closeSnackbar(props.snackbarKey)},2000)
                if(props.finallyCallback){
                    props.finallyCallback();
                }
            })
        },[])

    function buildErrorMessage() {
        if(errorDetails){
            return `${errorDetails.correlationId}: ${t(errorDetails.translationKey)}`;
        }

        return errorMessage !== null ? errorMessage : ""
    }

    return <Stack direction="row" alignItems="center" gap={1}>
        {(state === "Executing" ? <CircularProgress size={25} />: state === "Success" ?  <CheckCircle color={"success"} /> :   <Cancel color={"error"} />)}
        <Typography> {t(`customSnackbar${props.componentIndicator ? "." + props.componentIndicator : ""}.${state}`)} {buildErrorMessage()}</Typography>{" "}
    </Stack>
}