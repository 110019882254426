import { ReactNode, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "../../../MaterialOnFire/custom-hooks/useTranslation";
import { Close } from "@mui/icons-material";

type TStep = {
  label: string;
  isOptional: boolean;
  jsx: ReactNode;
  validateSelf: () => { isValid: boolean; messageKey?: string };
};

interface IWizard {
  steps: TStep[];
  onFinishComponent: ReactNode;
  closeCallback?: (navigateToItemId?: string) => void;
  wizardName: string;
}
export const Wizard = (props: IWizard) => {
  const [activeStep, setActiveStep] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return (
    <Paper sx={{ height: "100%" }}>
      <Stack
        direction={"column"}
        justifyContent={"space-evenly"}
        spacing={1}
        sx={{ height: "100%" }}
      >
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant={"h4"} sx={{ ml: 1 }}>
            {t(props.wizardName)}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              if (props?.closeCallback) props?.closeCallback();
            }}
            sx={{ mr: 1 }}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </Stack>

        <Stepper activeStep={activeStep}>
          {props.steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
          <Step key={"FinishComponent"}>
            <StepLabel>{t("Wizard.FinishStep")}</StepLabel>
          </Step>
        </Stepper>

        <div style={{ height: "90%", width: "100%" }}>
          {props.steps[activeStep]?.jsx || props.onFinishComponent}
        </div>

        <Stack direction={"row"} sx={{ width: "100%" }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={() => setActiveStep(activeStep - 1)}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          {props.steps[activeStep]?.isOptional && (
            <Button
              color="inherit"
              onClick={() => setActiveStep(activeStep + 1)}
              sx={{ mr: 1 }}
            >
              Skip
            </Button>
          )}
          <Button
            onClick={() => {
              const validationResult = props.steps[activeStep]?.validateSelf();
              if (validationResult.isValid) {
                setActiveStep(activeStep + 1);
              }

              if (validationResult.messageKey) {
                enqueueSnackbar(t(validationResult.messageKey), {
                  variant: validationResult.isValid ? "success" : "error",
                });
              }
            }}
          >
            {activeStep === props.steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};
