import exp from "node:constants";

interface OnboardingTemplateInstruction  {
    "type": "hardcoded" | "dynamic",
    "stepName": string,
    payload: any
}

export type HardcodedPayload = {
    "bytes": string[]
    "fPort": number
}


export interface HardcodedOnboardingTemplateInstruction extends OnboardingTemplateInstruction {
    "type": "hardcoded",
    payload: HardcodedPayload
}

export type DynamicPayload = {
    "variables": {
        [key: string]: string|number
    }
    "actionName": string
}

export const isDynamicPayload = (payload: any): payload is DynamicPayload => {
    return payload.variables && payload.actionName
}

export const isHardcodedPayload = (payload: any): payload is HardcodedPayload => {
    return payload.bytes && payload.fPort
}

export interface DynamicOnboardingTemplateInstruction extends OnboardingTemplateInstruction {
    "type": "dynamic",
    payload: DynamicPayload
}


export type OnboardingTemplateInstructionType = HardcodedOnboardingTemplateInstruction | DynamicOnboardingTemplateInstruction

export const isHardcodedOnboardingTemplateInstruction = (instruction: OnboardingTemplateInstruction): instruction is HardcodedOnboardingTemplateInstruction => {
    return instruction.type === "hardcoded" && instruction.payload && instruction.payload?.bytes?.length > 0 && instruction.payload?.fPort >= 0
}

export const isDynamicOnboardingTemplateInstruction = (instruction: OnboardingTemplateInstruction): instruction is DynamicOnboardingTemplateInstruction => {
    return instruction.type === "dynamic" && instruction.payload && instruction.payload?.variables && instruction.payload?.actionName
}

export const isOnboardingTemplateInstruction = (instruction: any): instruction is OnboardingTemplateInstruction => {
    return isHardcodedOnboardingTemplateInstruction(instruction) || isDynamicOnboardingTemplateInstruction(instruction)
}

export type TOnboardingTemplate = OnboardingTemplateInstructionType[]