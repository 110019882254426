import {backendRemoteConfig} from "../../../../MaterialOnFire/custom-hooks/types/firebaseRemoteConfigTypes";

export const getFullUrlFromBackendConfig = (backendConfig: backendRemoteConfig, callType: BackendURLS) => {
    const url = window.location.origin
    //todo decide if we need to remove this for production
    const devEndpoint = url.includes("localhost") ? "http://localhost:1338" : null

    switch (callType) {
        case "line":
        case "bar":
            return `${devEndpoint || backendConfig.url}/api/kpi/${backendConfig.tenantId}/queryTimeSeries`
        case "pie":
            return `${devEndpoint || backendConfig.url}/api/kpi/${backendConfig.tenantId}/queryTimeSeries`
        case "kpi":
        case "last":
            return `${devEndpoint || backendConfig.url}/api/kpi/${backendConfig.tenantId}/queryLast`
        case "upsertDeviceKPIIndex":
            return `${devEndpoint || backendConfig.url}/api/kpi/${backendConfig.tenantId}/upsertDeviceKPIIndex`
        case "upsertHardwareTypeKPIIndex":
            return `${devEndpoint || backendConfig.url}/api/kpi/${backendConfig.tenantId}/upsertHardwareTypeKPIIndex`
        case "upsertAllKPIIndices":
            return `${devEndpoint || backendConfig.url}/api/kpi/${backendConfig.tenantId}/upsertAllKPIIndices`
        case "queryNodesConnectedToGateway":
            return `${devEndpoint || backendConfig.url}/api/kpi/${backendConfig.tenantId}/queryConnectedNodes`
        case "lastMessagesForNode":
            return `${devEndpoint || backendConfig.url}/api/lastMessages/${backendConfig.tenantId}`

        default:
            throw new Error("Aggregation Strategy not supported")
    }


}

type BackendURLS = "line" | "bar" | "pie" | "kpi" | "last" | "upsertDeviceKPIIndex" | "upsertHardwareTypeKPIIndex" | "queryNodesConnectedToGateway" | "upsertAllKPIIndices" | "lastMessagesForNode";