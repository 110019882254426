import {UiEnhancements} from "../../MaterialOnFire/custom-hooks/useGetRoutes";
import {useSnackbar} from "notistack";
import {useGetKapionBackendClient} from "../kapionBackendClients/useGetKapionBackendClient";
import {GenericActionBarAction} from "../../MaterialOnFire/GenericUIFields/GenericActionBar";
import {HardwareType} from "../../../model/hardware-type/hardwareType";
import {FeedbackSnackbar} from "../../FeedbackSnackbar";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {LoraNode} from "../../../model/lora-node/LoraNode";
import {OnboardingTemplate} from "./HardwareTypeUI/OnboardingTemplate";
import {useCallback, useEffect, useMemo, useState} from "react";
import {TOnboardingTemplate} from "./HardwareTypeUI/OnboardingTemplateTypes";


export const useGetHardwareTypeUIEnhancer = () => {

    const {enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {backendKPIClient} = useGetKapionBackendClient();

    const selected = useSelector(
        (state: RootState) => state.maintenanceList.selected as HardwareType
    );

    const [onboardingTemplate,setOnboardingTemplate] = useState( selected?.onboardingTemplate ||[]);
    const [templateIsDirty, setTemplateIsDirty] = useState(false);
    useEffect(() => {
        setOnboardingTemplate(selected?.onboardingTemplate||[]);
        setTemplateIsDirty(false);
    }, [selected?.id]);

    const updateOnboardingTemplate = useCallback((newTemplate: TOnboardingTemplate) => {
        setOnboardingTemplate(newTemplate);
        setTemplateIsDirty(true);
    }, [setOnboardingTemplate, setTemplateIsDirty]);




    const hardwareTypeActionBarEnhancer = (
        item: any,
        actions: GenericActionBarAction[]
    ) => {
        const originalSaveAction = actions[0].action;
        actions[0].action= async ( newState:HardwareType, uiDefinition ) =>{

            const saveState = {...newState, onboardingTemplate: (templateIsDirty)? onboardingTemplate: newState.onboardingTemplate};
           await originalSaveAction(saveState, uiDefinition);

            const key=Date.now();
            enqueueSnackbar(<FeedbackSnackbar  closeSnackbar={closeSnackbar} promise={ backendKPIClient.updateHardwareTypeKPIIndex(newState.id)}
            componentIndicator={"upsertDeviceKPIIndex"}
            snackbarKey={key}/>, {variant: "default", persist: true, key: key})
         }
        return actions;
    }

    const hardwareTypeUiEnhancer = {
        enhanceActionBar: hardwareTypeActionBarEnhancer,
        injectJSX: [ <OnboardingTemplate hardwareTypeId={selected?.id} template={onboardingTemplate} updateTemplateCallback={updateOnboardingTemplate}/>]
    } as UiEnhancements;

    return { key: "hardwareTypes", enhancer: hardwareTypeUiEnhancer };
}