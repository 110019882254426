import {Box, TextField, Typography} from "@mui/material";
import * as React from "react";

export function KapionTextEditField(props: {
    t: JSX.Element | string,
    required: undefined | boolean,
    id: string,
    value: any,
    type: "button" | "checkbox" | "color" | "date" | "datetime-local" | "email" | "file" | "hidden" | "image" | "month" | "number" | "password" | "radio" | "range" | "reset" | "search" | "submit" | "tel" | "text" | "time" | "url" | "week" | string | undefined,
    disabled: undefined | boolean,
    valid: boolean,
    multiline?: boolean,
    onChange: (e:any) => void
}) {
    return <Box display={"flex"} width={"100%"} height={"100%"} padding={"0.5rem"} alignContent={"center"}
                justifyContent={"center"}>
        <Typography sx={{width: "33%"}} noWrap textOverflow={"ellipsis"}>
            {props.t}{props.required ? "*" : ""}
        </Typography>
        <TextField

            fullWidth

            variant={"standard"}
            required={props.required}
            id={props.id}
            multiline={props.multiline}
            maxRows={!!props.multiline? 10:1}
            //label={t(`${props.translationPrefix}.${props.fieldKey}`)}
            value={props.value || (props.type == "time" ? "00:00" : "")}
            disabled={props.disabled}
            type={props.type}
            error={!props.valid}
            onChange={props.onChange}
        />
    </Box>;
}