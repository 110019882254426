import {
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useGetRoutes } from "../../custom-hooks/useGetRoutes";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { setDrawerOpen } from "../appBarState";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { useTranslation } from "../../custom-hooks/useTranslation";
import { useMaterialOnFireNavigate } from "../../custom-hooks/useMaterialOnFireNavigate";
import {useEffect, useState} from "react";
import {PushPin} from "@mui/icons-material";
import React from "react";
export const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));



export const StyledDrawer = () => {
  const { getRoutes } = useGetRoutes();
  const { t } = useTranslation();
  const {navigate} = useMaterialOnFireNavigate();
  const openDrawer = useSelector((state: RootState) => state.appBar.drawerOpen);
  const dispatch: AppDispatch = useDispatch();
  const [pinned, setPinned] = useState(true);

  const closeDrawer = () => {
    if (!pinned) {
      dispatch(setDrawerOpen(false));
    }
  }

  useEffect(() => {
    const pinPreference = localStorage.getItem("drawer");
    const isPinned = pinPreference === "true";
    if(pinPreference){
      setPinned(isPinned);
    }
  },[])

  useEffect(() => {
    dispatch(setDrawerOpen(pinned))
  },[pinned])

  return (
    <Drawer
      variant="permanent"
      open={openDrawer}
      onMouseEnter={()=>(pinned ? null : dispatch(setDrawerOpen(true)))}
      onMouseLeave={()=>(pinned ? null : dispatch(setDrawerOpen(false)))}
      PaperProps={{ sx: { backgroundColor: "paper.drawer" } }}
      transitionDuration={400}
      onClose={() => {
        dispatch(setDrawerOpen(false));
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: [1],
        }}
      >
        <Grid size={{ xs: 1 }}>
          <IconButton sx={{width: "100%", height:"100%"}} color={ pinned? "secondary" : "primary"} onClick={ () => {
            const pinPreference = localStorage.getItem("genericListPinned");
            const isPinned =pinPreference === "true"
            setPinned(!isPinned);
            localStorage.setItem("genericListPinned", `${!isPinned}`)
          }}>
            <PushPin/>
          </IconButton>
        </Grid>
        <IconButton
          onClick={() => dispatch(setDrawerOpen(false))}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <List>
        {getRoutes().map((route) => {
          return (
            <React.Fragment key={`routeKey-${route.name}`}>
              <ListItemButton
                onClick={() => {
                  navigate("..",route?.route || "", "");
                  closeDrawer();
                }}
              >
                {/*Hier benötigen wir noch die Iconen*/}
                <Tooltip title={t("Drawer." + route.name)} placement={"right"}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                </Tooltip>
                <Typography>{t("Drawer." + route.name)}</Typography>
              </ListItemButton>
              <Divider />
            </React.Fragment>
          );
        })}
      </List>

    </Drawer>
  );
};
