import {TTNMessage} from "../../../model/lora-node/Messages/ttnMessages";
import {useEffect, useState} from "react";
import {getFullUrlFromBackendConfig} from "./Dashboards/DiagramHelper/getFullUrlFromBackendConfig";
import {useGetFirebaseConfigParameter} from "../../MaterialOnFire/custom-hooks/useGetFirebaseConfigParameter";
import {
    BACKEND_CONFIG_PARAMETER_NAME,
    backendRemoteConfig
} from "../../MaterialOnFire/custom-hooks/types/firebaseRemoteConfigTypes";
import axios from "axios";
import {getAuth} from "firebase/auth";


export const useGetLastMessages = (dev_eui: string | undefined): TTNMessage[] => {
    const [lastMessages, setLastMessages] = useState([])

    const backendConfig = useGetFirebaseConfigParameter<backendRemoteConfig>(BACKEND_CONFIG_PARAMETER_NAME)

    function fetchLastMessages() {
        if (!backendConfig) {
            return;
        }

        getAuth().currentUser?.getIdToken().then((token: string) => {
            const requestBody = {
                "devEui": dev_eui,
                "page": 0,
                "pageSize": 50
            }

            const request = axios.post(getFullUrlFromBackendConfig(backendConfig, "lastMessagesForNode"), requestBody,
                {
                    headers: {
                        "Authorization": token
                    }
                }
            )

            request.then(response => setLastMessages(response.data.map((message: TTNMessage) => {
                let received_at = getTime(message);
                let isNew = received_at ? (Math.abs((new Date().getTime() - received_at.getTime()) / 1000) <= 10) : false;
                return {...message, time: received_at || "", isNew: isNew}
            })));
        })
    }

    useEffect(() => {
        if (!backendConfig) {
            return;
        }

        fetchLastMessages();

        const interval = setInterval(() => {
            fetchLastMessages();
        }, 10000);

        //Clearing the interval
        return () => clearInterval(interval);
    }, [backendConfig, dev_eui]);

    return lastMessages;
}



const getTime = (message: any) => {
    if (message.enqueued_at) {
        return new Date(message.enqueued_at);
    }

    if (message.received_at) {
        return new Date(message.received_at);
    }

}
