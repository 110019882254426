import { useEffect} from "react";
//import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useDispatch, useSelector } from "react-redux";
import {
  MapContainer,
} from "react-leaflet";
import MapHandler from "./MapHandler";
import { LoraNode } from "../../model/lora-node/LoraNode";
import {cleanup, setActiveComponent, setHitlist} from "../MaterialOnFire/app-bar/appBarState";
import {MapTileProvider} from "./MapTileProvider";
import { RootState } from "../../store";

const defaultCenter = {
  lat: 51.25644446909589,
  lng: 7.14607843104575,
};

const getInitialMapCenter = () => {
  let urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("lat") && urlParams.has("lng")) {
    let lat = Number.parseFloat(urlParams.get("lat") || "0");
    let lng = Number.parseFloat(urlParams.get("lng") || "0");
    return { lat: lat, lng: lng };
  } else {
    let lat = localStorage.getItem("mapCenterLat");
    let lng = localStorage.getItem("mapCenterLng");
    if (lat && lng) {
      return {
        lat: Number.parseFloat(lat),
        lng: Number.parseFloat(lng),
      };
    }
  }

  return defaultCenter;
};

const defaultZoom = 13;
const getInitialZoom = () => {
  let urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("zoomLevel")) {
    return Number.parseInt(urlParams.get("zoomLevel") || "0");
  } else {
    let zoomLevel = localStorage.getItem("mapZoomLevel");

    if (zoomLevel) {
      return Number.parseInt(zoomLevel);
    }
  }

  return defaultZoom;
};

function KapionMap() {
  const dispatch = useDispatch();

  const nodes = useSelector(
    (state: any) => state["loraNodes"]?.items as LoraNode[]
  );
  const searchString = useSelector(
    (state: RootState) => state.appBar.searchString
  );

  const center = getInitialMapCenter();
  const zoom = getInitialZoom();

  useEffect(() => {
    dispatch(setActiveComponent("map"))
    return () => {
      dispatch(cleanup())
    }
  }, []);

  useEffect(() => {
    if (nodes) {
      const filteredNodes = nodes.filter((value) => {
        if (searchString === "") {
          return true;
        }
        return JSON.stringify(value).includes(searchString);
      })

      dispatch(setHitlist(filteredNodes.map(node => {
        return {label: node.name || node.id, id: node.id}
      })))
    }
  }, [nodes, searchString]);




  return (
    <MapContainer
      center={[center.lat, center.lng]}
      zoom={zoom}
      maxZoom={25}
      scrollWheelZoom={true}
      className="markercluster-map"
      style={{ height: "100%", width: "100%" }}
    >
      {/*<TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        maxNativeZoom={19}
        maxZoom={25}
      />*/}

      <MapTileProvider/>

      <MapHandler loraNodes={nodes} />
    </MapContainer>
  );
}

export default KapionMap;
