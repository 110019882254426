import { Button, Paper } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { useSnackbar } from "notistack";
import { FeedbackSnackbar } from "../../FeedbackSnackbar";


export const MailNotVerified = () =>{
  const {enqueueSnackbar}= useSnackbar();
  return <Grid
    container
    justifyContent={"center"}
    alignContent={"center"}
    sx={{ height: "100%", width: "100%", backgroundColor: "paper.dark" }}
  >
    <Grid
      container
      justifyContent={"center"}
      spacing={2}
      alignItems={"center"}
      sx={{ width: "40%", p: 4, backgroundColor: "paper.light" }}
      component={Paper}
    >

      <Grid size={{ xs: 12 }}>
        <p> Ihre E-Mail ist nicht verifiziert, wollen Sie eine Verifizierungs-Mail versenden?</p>
      </Grid>
      <Grid size={{ xs: 12 }} justifyContent={"center"} alignItems={"center"}>
        <Button variant={"contained"} onClick={ () => {
          const auth = getAuth();
          if(auth.currentUser)
            enqueueSnackbar( <FeedbackSnackbar promise={sendEmailVerification(auth.currentUser) } closeSnackbar={()=>{}} snackbarKey={1} />)

        }}> Mail senden</Button>
      </Grid>

    </Grid>
  </Grid>


}