import {LoraNode} from "../../../model/lora-node/LoraNode";
import {useEffect, useState} from "react";

export interface OrganizedData {
    [key: string]: {
        loraNode: LoraNode[],
    }
}

export interface OrganizedDataCumulative {
    none: OrganizedData,
    hardwareType: OrganizedData,
    group: OrganizedData

}

const getKeyBySortMode = (node: LoraNode, sortMode: "none"|"hardwareType"|"group") => {
    switch (sortMode) {
        case "none":
            return "A-Z"
        case "hardwareType":
            return node.hardwareType?.name || "unknown"
        case "group":
            return node.group?.name || "unknown"
    }
}

export const useGetOrganizedNodes = (loraNodes: LoraNode[]) => {

    const [organizedData, setOrganizedData] = useState<OrganizedDataCumulative>({
        none: {}, hardwareType: {}, group: {}
    })

    useEffect(() => {
            const organizedData: OrganizedDataCumulative = {
                none: {},
                hardwareType: {},
                group: {}
            }

            const copy = [...loraNodes]
            copy.sort((a, b) => (a.name || a.id).localeCompare(b.name || b.id)).forEach((node) => {

                const key = getKeyBySortMode(node, "none")

                const array = organizedData.none[key]
                if (array) {
                    array.loraNode.push(node)
                } else {
                    organizedData.none[key] = {loraNode: [node]}
                }

            })

            copy.sort((a, b) => (a.hardwareType?.name || "unknown").localeCompare(b.hardwareType?.name || "unknown")).forEach((node) => {

                const key = getKeyBySortMode(node, "hardwareType")

                const array = organizedData.hardwareType[key]
                if (array) {
                    array.loraNode.push(node)
                } else {
                    organizedData.hardwareType[key] = {loraNode: [node]}
                }

            })

            copy.sort((a, b) => (a.group?.name || "unknown").localeCompare(b.group?.name || "unknown")).forEach((node) => {

                const key = getKeyBySortMode(node, "group")

                const array = organizedData.group[key]
                if (array) {
                    array.loraNode.push(node)
                } else {
                    organizedData.group[key] = {loraNode: [node]}
                }

            })

            setOrganizedData(organizedData)

    }, [loraNodes]);


    return organizedData
}


