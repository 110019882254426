import {KapionLineChart} from "./KapionLineChart";
import {KapionBarChart} from "./KapionBarChart";
import {KapionPieChart} from "./PieChart";
import {TimeSeriesEntry} from "../../types/type";
import {KPICard} from "../../../../../nodeDetails/KPI-Card";
import {KapionKPIChart} from "./KapionKPIChart";

export interface DiagramProps{
    data:TimeSeriesEntry[];
    nodes: string[]
    kpis: string[]

}



export const useGetDiagramType = ( diagramType : "line" |"bar"|"pie"|"kpi" , diagramProps:DiagramProps) : React.ReactNode => {
    if(!diagramProps.data || !diagramProps.nodes || !diagramProps.kpis){
        return <></>
    }
    if(diagramType === "line"){
        return <KapionLineChart {...diagramProps}/>
    }
    if(diagramType === "bar"){
        return <KapionBarChart  {...diagramProps}/>
    }

    if(diagramType === "pie"){
        return <KapionPieChart  {...diagramProps}/>
    }

    if(diagramType === "kpi"){
        return <KapionKPIChart  {...diagramProps}/>
    }
    return <></>

}