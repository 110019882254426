import {DiagramProperties} from "../types/type";
import {Box} from "@mui/material";
import "./Diagram.css"
import {DiagramCard} from "./DiagramCard";

import {useGetDiagramType} from "./DiagramTypes/useGetDiagramType";

import {useGetDiagramData} from "../DiagramHelper/useGetDiagramData";


export interface DiagramParams {
    diagramProps: DiagramProperties;
    setDragStatus: (dashboardName: string, dragging: boolean, origin: "preview" | "dashboard" | "config",
                    diagramProperties: DiagramProperties | null, position: {
            mouseX: number, mouseY: number, diagramX: number, diagramY: number
        }) => void;
    positionProps: { posX: number, posY: number, sizeX: number, sizeY: number };
    onDrag: (e: any) => void;

    onDragEnd(x: number, y: number, xBottom?: number, yBottom?: number): void;

    width?: string | number;
    height?: string | number;
    origin: "dashboard" | "preview" | "config";
    resizable?: boolean;
    deleteFromDashboard: () => void;
}

export const Diagram = (props: DiagramParams) => {
    const diagramData = useGetDiagramData( props.diagramProps)

    const KapionDiagram = useGetDiagramType( props.diagramProps.diagramProperties?.diagramType ,{ data: diagramData.data.timeSeries, kpis:props.diagramProps.influxQueryProperties?.values || [], nodes: props.diagramProps.influxQueryProperties?.nodeIds || []})

    return <DiagramCard key={`${props.positionProps.posX}-${props.positionProps.posY}`}{...props}>
        <Box flexGrow={1} overflow={"hidden"}>
        {KapionDiagram}
        </Box>

    </DiagramCard>

}