import { useDispatch, useSelector } from "react-redux";
import { addReducer, AppDispatch, RootState } from "../../store";
import React, { ReactElement, useEffect, useState } from "react";
import { GenericMaintenanceList } from "./GenericUIViews/GenericMaintenanceList/GenericMaintenanceList";
import {
  Group,
} from "../../model/groups/groupState";
import {
  Typography,
} from "@mui/material";
import  Grid  from "@mui/material/Grid2";

import { useGetSaveAndDeleteActions } from "./custom-hooks/useGetSaveAndDeleteActions";
import { useGetUIDefinition } from "./custom-hooks/useGetUIDefinition";
import { UiEnhancements } from "./custom-hooks/useGetRoutes";
import { useSnackbar } from "notistack";
import {
  setInitUi,
  setIsDirty,
} from "./GenericUIViews/GenericMaintenanceList/GenericMaintenanceListState";
import {FeedbackSnackbar} from "../FeedbackSnackbar";
import { useMaterialOnFireNavigate } from "./custom-hooks/useMaterialOnFireNavigate";

export const GenericUI = (props: {
  model: string;
  uiEnhancer?: UiEnhancements;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {navigate} = useMaterialOnFireNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const getUIDefinition = useGetUIDefinition(props.model);

  const reducer = addReducer(getUIDefinition());
  const [wizardVisible, setWizardVisible] = useState(false);

  const selected = useSelector(
    (state: RootState) => state.maintenanceList.selected
  );

  //@ts-ignore
  const items = useSelector((state) => state[getUIDefinition()?.model]?.items);

  useEffect(() => {
    //@ts-ignore
    dispatch(reducer.fetchAll());
    dispatch(setInitUi());
  }, [props.model]);

  let actions = useGetSaveAndDeleteActions<any>({
    uiDefinition: getUIDefinition(),
    saveAction: (newState) => {
      if (reducer) {
        dispatch(reducer.actions.update(newState));
        const upsertItem = reducer.crudService
          .upsertItem(newState);

        const key = Date.now();
        enqueueSnackbar(<FeedbackSnackbar closeSnackbar={closeSnackbar} promise={upsertItem}
                                          componentIndicator={"genericSave"}
                                          successCallback={() => dispatch(setIsDirty(false))}
                                          snackbarKey={key}/>, {variant: "default", persist: true, key: key})
      }
    },
    deleteAction: (newState) => {
      if (reducer) {
        dispatch(reducer.actions.delete(newState));
        const deleteItem = reducer.crudService
          .deleteItem(newState);

        const key = Date.now();
        enqueueSnackbar(<FeedbackSnackbar closeSnackbar={closeSnackbar} promise={deleteItem}
                                          componentIndicator={"genericDelete"}
                                          successCallback={() => dispatch(setIsDirty(false))}
                                          snackbarKey={key}/>, {variant: "default", persist: true, key: key})

      }
    },
  });

  if (props.uiEnhancer?.enhanceActionBar) {
    actions = props.uiEnhancer.enhanceActionBar(selected, actions);
  }
  return props?.uiEnhancer?.newWizard && wizardVisible ? (
    //This will enhance every wizard with a closeCallback
    React.cloneElement(props.uiEnhancer.newWizard as ReactElement<any>, {
      closeCallback: (navigateToItemId?: string) => {
        setWizardVisible(false);

        if (navigateToItemId) {
          navigate("..", getUIDefinition().model , navigateToItemId);
        }
      },
    })
  ) : (
    <Grid id="generic-ui-wrapper" container sx={{ height: "100%", marginTop: "0.25rem", overflow: "hidden" }} spacing={2}>
        <GenericMaintenanceList
          list={items}
          key={"GenericMaintenanceList"}
          routingOptions={{
            idFieldName: "id",
            baseRoute: getUIDefinition().model,
          }}
          listItemDisplayFunction={(item: Group) => {
            return <Typography noWrap={true}>{item.name}</Typography>;
          }}
          addNewAction={() => {
            //if for the UI an Override is defined we call that
            if (props?.uiEnhancer?.newWizard) {
              setWizardVisible(true);
              return;
            }

            if (reducer) {
              let newGroup = reducer.crudService.getNewEmptyItem();
              dispatch(reducer.actions.add(newGroup));
              navigate(".." , getUIDefinition().model ,newGroup.id);
            }
          }}
          translationPrefix={props.model}
          uiDescriptorTable={getUIDefinition().uiDescriptorDefinition || []}
          maintenanceViewActions={actions}
          injectJSX={props.uiEnhancer?.injectJSX}
          replacementJSX={props.uiEnhancer?.replacementJSX}
        />
    </Grid>
  );
};
