import { TCreateLoraNodeDataModel } from "../CreateLoraNodeWizardWrapper";
import { Button, TextField } from "@mui/material";
import { useTranslation } from "../../../../MaterialOnFire/custom-hooks/useTranslation";
import {  useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { fireBaseApp } from "../../../../MaterialOnFire/firebase-config";
import { useSnackbar } from "notistack";
import {FeedbackSnackbar} from "../../../../FeedbackSnackbar";
import Grid from "@mui/material/Grid2";
import {useGetKapionBackendClient} from "../../../kapionBackendClients/useGetKapionBackendClient";

export const getRegisterOTAADeviceFunction = () => {
  const functions = getFunctions(fireBaseApp, "europe-west1");
  const registerOTAADevice = httpsCallable(functions, "registerOTAADevice");

  return registerOTAADevice;
};

export const getRegisterABPDeviceFunction = () => {
  const functions = getFunctions(fireBaseApp, "europe-west1");
  const registerABPDevice = httpsCallable(functions, "registerABPDevice");

  return registerABPDevice;
};

export const FinishStep = (props: {
  newLoraNode: TCreateLoraNodeDataModel;
  updateNewLoraNode: (newState: TCreateLoraNodeDataModel) => void;
  closeOnSuccess?: (newItemId: string) => void;
}) => {
  const { t } = useTranslation();
  const [finishStatus, setFinishStatus] = useState<
    "successful" | "failed" | undefined
  >();
  const [errorMessage, setErrorMessage] = useState("");
  const { enqueueSnackbar,closeSnackbar } = useSnackbar();

  const {backendKPIClient, onboardingClient} = useGetKapionBackendClient()


  const handleCreation = () => {
    //handle OTAA
    if (props.newLoraNode.activationType === "OTAA") {
      const createPromise = createOTAADevice();
        const key = Date.now();
        enqueueSnackbar(<FeedbackSnackbar successCallback={
          () =>{
            if(props.newLoraNode?.dev_eui && props?.newLoraNode?.hardwareType?.id){
              enqueueSnackbar(<FeedbackSnackbar promise={backendKPIClient.updateDeviceKPIIndex(props.newLoraNode.dev_eui.replaceAll(" ",""), props.newLoraNode.hardwareType.id)} closeSnackbar={closeSnackbar} snackbarKey={1337}/>,{variant:"default", persist: true, key:key})
            }
          }

        } closeSnackbar={closeSnackbar} promise={createPromise} snackbarKey={key}/>,{variant:"default", persist: true, key:key})
    }

    if (props.newLoraNode.activationType === "ABP") {
      const createPromise = createABPDevice();
        const key = Date.now();
        enqueueSnackbar(<FeedbackSnackbar successCallback={() =>{
          if(props.newLoraNode?.dev_eui && props?.newLoraNode?.hardwareType?.id){
            enqueueSnackbar(<FeedbackSnackbar promise={backendKPIClient.updateDeviceKPIIndex(props.newLoraNode.dev_eui.replaceAll(" ",""), props.newLoraNode.hardwareType.id)} closeSnackbar={closeSnackbar} snackbarKey={1337}/>,{variant:"default", persist: true, key:key})
          }} }closeSnackbar={closeSnackbar} promise={createPromise} snackbarKey={key}/>,{variant:"default", persist: true, key:key})
      }

    if(props.newLoraNode?.hardwareType?.onboardingTemplate && props.newLoraNode.dev_eui && props.newLoraNode?.hardwareType?.id && props.newLoraNode.deviceId && props.newLoraNode.applicationId) {
      onboardingClient.createOnboardingDocument(props.newLoraNode.applicationId,props.newLoraNode.deviceId,props.newLoraNode.dev_eui.replaceAll(" ",""), props.newLoraNode.hardwareType.id)
    }
  };

  const createOTAADevice = async () => {
    const registerOTTADevice = getRegisterOTAADeviceFunction();
    let result = (
      await registerOTTADevice({
        ...props.newLoraNode,
        dev_eui: props.newLoraNode?.dev_eui?.replaceAll(" ", ""),
        join_eui: props.newLoraNode?.join_eui?.replaceAll(" ", ""),
        application_root_key:
          props.newLoraNode?.application_root_key?.replaceAll(" ", ""),
      })
    ).data as unknown as any;
    if (result?.code === 200) {
      enqueueSnackbar(t("createLoraNodeWizard.finishstep.success"), {
        variant: "success",
      });
      setFinishStatus("successful");
      if (props.closeOnSuccess) {
        props.closeOnSuccess(
          props.newLoraNode?.dev_eui?.replaceAll(" ", "") || "$"
        );
      }
    } else {
      setFinishStatus("failed");
      setErrorMessage(result);
    }
  };

  const createABPDevice = async () => {
    const registerABPDevice = getRegisterABPDeviceFunction();
    let result = (
      await registerABPDevice({
        ...props.newLoraNode,
        dev_eui: props.newLoraNode?.dev_eui?.replaceAll(" ", ""),
        join_eui: props.newLoraNode?.join_eui?.replaceAll(" ", ""),
        application_root_key:
          props.newLoraNode?.application_root_key?.replaceAll(" ", ""),
        dev_addr: props.newLoraNode?.dev_addr?.replaceAll(" ", ""),
        network_session_key: props.newLoraNode?.network_session_key?.replaceAll(
          " ",
          ""
        ),
      })
    ).data as unknown as any;
    if (result?.code === 200) {
      enqueueSnackbar(t("createLoraNodeWizard.finishstep.success"), {
        variant: "success",
      });
      setFinishStatus("successful");
      if (props.closeOnSuccess) {
        props.closeOnSuccess(
          props.newLoraNode?.dev_eui?.replaceAll(" ", "") || "$"
        );
      }
    } else {
      setFinishStatus("failed");
      setErrorMessage(result);
    }
  };

  return (
    <Grid container spacing={2} sx={{ p: 1 }}>
      {Object.keys(props.newLoraNode).map((key) => {
        return (
          <Grid
              key={`${key}`}
              size={{xs:6}}
          >
            <TextField
              fullWidth
              disabled={true}
              label={t(`createLoraNodeWizard.finishstep.${key}`)}
              //@ts-ignore
              value={typeof props.newLoraNode[key] === "object" ? props.newLoraNode[key]?.name : props.newLoraNode[key]}
            />
          </Grid>
        );
      })}
      {finishStatus === "failed" ? (
        <Grid size={{xs:12}}>
          <TextField color={"error"} multiline={true} value={errorMessage} />
        </Grid>
      ) : (
        <></>
      )}
      <Grid container size={{xs:12}} justifyContent={"center"} sx={{ p: 1 }}>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={handleCreation}
        >
          {t("createLoraNodeWizard.finishstep.finishButtonLabel")}
        </Button>
      </Grid>
    </Grid>
  );
};
