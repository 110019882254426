import * as React from "react";
import { IMaskInput } from "react-imask";
import { GenericFieldInterface } from "./GenericFieldInterface";


import { useEffect, useMemo, useState } from "react";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { setHasError } from "../GenericUIViews/GenericMaintenanceList/GenericMaintenanceListState";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { useTranslation } from "../custom-hooks/useTranslation";

interface GenericTextEditFieldInterface extends GenericFieldInterface {
  type: React.InputHTMLAttributes<unknown>["type"];
}
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}



export const GenericMaskedField = (props: GenericTextEditFieldInterface) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(true);
  const [localValue, setLocalValue] = useState<any>(undefined);

  const dispatch: AppDispatch = useDispatch();

  const fieldMask = useMemo( () => {
    return  React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(customProps, ref: any) {
      const { onChange, ...other } = customProps;

      return (
        <IMaskInput
          {...other}
          mask={props.uiDescriptor.mask ||"#"}
          placeholderChar={"#"}

          lazy={false}
          definitions={{
            "#":  /[0-9A-Fa-f]/,
          }}
          inputRef={ref}
          onAccept={ (value) => {
            setLocalValue((value as string).replaceAll(" ","").replaceAll("#",""))
          }  }
          onComplete={(value) => {
            if (onChange)
              onChange({ target: { name: customProps?.name, value: value as string } })
          }
          }

        />

      );
    }
  );},[props.uiDescriptor.mask, props.value, ])

  useEffect(() => {
    setLocalValue(props.value || "");
  }, [props.value]);

  useEffect(() => {
    if (props.uiDescriptor.validator) {
      const regex = new RegExp(props.uiDescriptor.validator);
      if (localValue) {
        const testResult = regex.test(localValue);
        dispatch(setHasError(!testResult));
        setIsValid(testResult);
      }
    }
  }, [localValue, props.uiDescriptor.validator]);

  return (
    <Grid size={{ xs:props.uiDescriptor.size || 4 }}>
      <TextField
        key={props.fieldKey}
        fullWidth
        required={props.required}
        id={props.fieldKey}
        label={t(`${props.translationPrefix}.${props.fieldKey}`)}
        value={localValue || (props.type === "time" ? "00:00" : "")}
        disabled={props.disabled}
        type={props.type}
        error={!isValid}
        onChange={(e) => {
          const changedValue = e.target.value.replaceAll("#","").replaceAll(" ","")
          props.onChange && changedValue !== props.value && props.onChange({
            //@ts-ignore
            currentTarget: { value: changedValue },
          })

        }}
        InputProps={ { inputComponent : fieldMask as any }}
      />
    </Grid>
  );
};
