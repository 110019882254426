import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { LoraNode } from "../../../model/lora-node/LoraNode";
import { useEffect, useState } from "react";
import { isPointInPolygon } from "geolib";
import { GenericNestedList } from "../../MaterialOnFire/GenericUIViews/GenericNestedList/GenericNestedList";
import { getLoraNodeActionsFromHardWareType } from "../../custom-hooks/uiEnhancers/MaintainLoraNodeActions";
import { Group } from "../../../model/groups/groupState";
import { HardwareType } from "../../../model/hardware-type/hardwareType";
import { useSnackbar } from "notistack";
import { useMaterialOnFireNavigate } from "../../MaterialOnFire/custom-hooks/useMaterialOnFireNavigate";

export const NodeOverview = () => {
  const mapState = useSelector((state: RootState) => state.map);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const groupsState = useSelector(
    (state: any) => state["groups"]?.items as Group[]
  );

  const hardwareTypeState = useSelector(
    (state: any) => state["hardwareTypes"]?.items as HardwareType[]
  );
  const [visibleNodes, setVisibleNodes] = useState<LoraNode[]>([]);
  const {navigate} = useMaterialOnFireNavigate();

  useEffect(() => {
    if (mapState?.visibleNodes && mapState?.viewPort) {
      let viewPort = mapState.viewPort;
      let newVisibleNodes: LoraNode[] = mapState.visibleNodes.filter(
        (node: LoraNode) => {
          return isPointInPolygon(
            { lat: node.location.lat, lng: node.location.lng },
            [
              viewPort.topLeft,
              viewPort.topRight,
              viewPort.bottomRight,
              viewPort.bottomLeft,
            ]
          );
        }
      );
      setVisibleNodes(newVisibleNodes);
    }
  }, [mapState.visibleNodes, mapState.viewPort, mapState]);

  return (
    <GenericNestedList
      list={visibleNodes}
      isSelected={(item: LoraNode) => item.id === mapState.selectedElement?.id}
      listItemOnClick={(item: LoraNode) => () => {
        navigate( "","loraNodes" , item.id);
      }}
      getListItemVisualizer={(item: LoraNode) => {
        return <Typography>{item.name}</Typography>;
      }}
      getListItemActions={(item: LoraNode) => {
        if (item.hardwareType) {
          let localHardwareType = item.hardwareType;
          if (hardwareTypeState) {
            let index = hardwareTypeState.findIndex(
              (value) => value.id === item?.hardwareType?.id
            );
            localHardwareType =
              index >= 0 ? hardwareTypeState[index] : localHardwareType;
          }

          return getLoraNodeActionsFromHardWareType(
            localHardwareType,
            hardwareTypeState,
            enqueueSnackbar,
            closeSnackbar
          );
        }
        return [];
      }}
      getNestingCriteriaFromListItem={(item: LoraNode) => {
        return item?.group?.id || "NO_GROUP";
      }}
      getNestingCriteriaVisualizer={(item: LoraNode) => {
        if (groupsState && item?.group?.id) {
          const index = groupsState.findIndex(
            (value) => item?.group?.id === value.id
          );
          if (index >= 0)
            return (
              <Typography>{groupsState[index]?.name || "NO_GROUP"} </Typography>
            );
        }
        return <Typography> {item.group?.name || "NO_GROUP"} </Typography>;
      }}
      getNestingCriteriaActions={(item: LoraNode) => {
        const index = groupsState.findIndex(
          (value) => value.id === item?.group?.id
        );
        if (index >= 0) {
          let hardwareType = groupsState[index].hardwareType;
          if (hardwareType) {
            if (hardwareType) {
              return getLoraNodeActionsFromHardWareType(
                hardwareType,
                hardwareTypeState,
                enqueueSnackbar,
                closeSnackbar,
                true
              );
            }
          }
        }
        return [];
      }}
    />
  );
};
