

export interface DiagramCoreProperties {
  diagramType:   "line"| "bar" | "pie" | "value" | "kpi";
  aggregationInterval?: "1m" | "5m" | "15m" | "1h" | "1d" | "1w" | "1M" | "1y";
  aggregationStrategy?: "sum"|"mean"|"median"|"spread"|"count";
    "startTime": number|"now()"
    "duration": number;
    "durationUnit": "m"| "h"| "d"| "w"| "M"| "y";
}

export interface TimeSeriesProperties extends DiagramCoreProperties{

    "diagramType": "line";
}

/*typeguard for TimeSeriesProperties based on:
    -startTime being a number and not null or undefined or "now"
    -duration being a number and not 0
    -the diagramType being line
    */
export function isTimeSeriesProperties(properties: TimeSeriesProperties | LastDataProperties): properties is TimeSeriesProperties {

    return ( (properties as TimeSeriesProperties)?.startTime !== undefined
        && (properties as TimeSeriesProperties)?.startTime !== null
        || (properties as TimeSeriesProperties)?.startTime === "now()" )
        && (properties as TimeSeriesProperties)?.duration !== undefined
        && (properties as TimeSeriesProperties)?.duration !== null
        && (properties as TimeSeriesProperties)?.duration !== 0
        && (properties as TimeSeriesProperties)?.diagramType === "line";
}


/*typeguard for LastDataProperties based on:
  -diagramType being bar or pie
  -aggregationStrategy being "sum"|"mean"|"median"|"spread"|"count";
 */
export function isLastDataProperties(properties: TimeSeriesProperties | LastDataProperties): properties is LastDataProperties {
    return ( (properties as LastDataProperties)?.diagramType === "bar" || (properties as LastDataProperties)?.diagramType === "pie" )
        && ( (properties as LastDataProperties)?.aggregationStrategy === "sum"
            || (properties as LastDataProperties)?.aggregationStrategy === "mean"
            || (properties as LastDataProperties)?.aggregationStrategy === "median"
            || (properties as LastDataProperties)?.aggregationStrategy === "spread"
            || (properties as LastDataProperties)?.aggregationStrategy === "count");
}

export interface LastDataProperties extends DiagramCoreProperties{
    diagramType: "bar" | "pie" |"kpi";

}

export interface InfluxQueryProperties {
    influxBucketName: string; //for Nodes this is the hardwareTypeName
    values: string[]; //for Nodes this the KPIs Name
    nodeIds: string[]|null; //for Nodes this is the nodeId
}

/*typeguard for InfluxQueryProperties based on:
    -influxBucketName being a string and not null or undefined
    -values being an array of strings and not null or undefined
    -nodeIds being an array of strings or null
 */
export function isInfluxQueryProperties(properties: InfluxQueryProperties): properties is InfluxQueryProperties {
    return (properties.influxBucketName !== undefined && properties.influxBucketName !== null)
        && (properties.values !== undefined && properties.values !== null)
        && (properties.nodeIds !== undefined);
}


export interface DiagramProperties {
    id: string;
    name: string;
    diagramProperties: TimeSeriesProperties|LastDataProperties;
    influxQueryProperties: InfluxQueryProperties;
}

export interface DashboardDiagramConfig {
    DiagramDefinition: DiagramProperties
    posX:number|string;
    posY:number|string;
    sizeX:number|string;
    sizeY:number|string;
}


export interface DashboardProperties {
    id: string;
    name: string;
    diagrams: DashboardDiagramConfig[]
}



/*All Types for communication with backend"*/

export interface TimeSeriesEntry {
    key: string,
    time:string,
    nodeId: string,
    [key: string]: string|number
}
export interface TimeSeriesData{
    timeSeries:TimeSeriesEntry[]

}

export const isTimeSeriesEntry = (entry: any): entry is TimeSeriesEntry => {
    const isEntry = (entry as TimeSeriesEntry).nodeId !== undefined
  //  console.log("is Entry", isEntry, entry)
    return isEntry
}
export const isTimeseriesData = (data: any): data is TimeSeriesData => {
    if( (data as TimeSeriesData).timeSeries !== undefined && ( data as TimeSeriesData).timeSeries.length > 0){

        return  ((data as TimeSeriesData).timeSeries.map(entry => isTimeSeriesEntry(entry)).reduce( (acc, curr) => acc && curr, true))

    }else return (data as TimeSeriesData).timeSeries !== undefined && (data as TimeSeriesData).timeSeries.length === 0;
}