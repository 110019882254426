import { Save } from "@mui/icons-material";
import MessageIcon from "@mui/icons-material/Message";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  HardwareInstruction,
  HardwareType,
} from "../../../model/hardware-type/hardwareType";
import { LoraNode } from "../../../model/lora-node/LoraNode";
import {
  getSendDownlinkFunction,
  loraNodeCRUDServices,
} from "../../../model/lora-node/loraNodeFirebaseServices";
import { GenericActionBarAction } from "../../MaterialOnFire/GenericUIFields/GenericActionBar";
import { AppDispatch, RootState } from "../../../store";
import { updateLoraNode } from "../../../model/lora-node/loraNodeState";
import { useGetIconList } from "../../MaterialOnFire/custom-hooks/useGetIconList";
import { useSelector } from "react-redux";
import { Group } from "../../../model/groups/groupState";

import HttpsCallableResult from "firebase/compat/";
import {OptionsObject, SnackbarKey, SnackbarMessage} from "notistack";
import {useState} from "react";
import {CircularProgress, Zoom} from "@mui/material";
import {FeedbackSnackbar} from "../../FeedbackSnackbar";

function mapHardwareInstructionToActionBarAction(
  instruction: HardwareInstruction,
  getIcon: (iconName: string, sx?: any) => JSX.Element,
  enqueueSnackbar?: (message: SnackbarMessage, options: OptionsObject) => void,
  closeSnackbar? : ((key?: (SnackbarKey | undefined)) => void),
  snackbarProps? : any,
  setSnackbarProps? : any
) {
  return {
    action: (state: LoraNode | Group) => {
      const isLoraNode = Object.hasOwn(state,"ttnPayload")
      const downlink = getSendDownlinkFunction()({
        isGroup: !isLoraNode,
        addDownlinkPath:  ( !isLoraNode || !(state as LoraNode).ttnPayload?.ids?.join_eui ),
        targetId: state.id,
        instructionId: instruction.id,
      });

      if (enqueueSnackbar && closeSnackbar) {
        const key = Date.now();
        enqueueSnackbar(<FeedbackSnackbar closeSnackbar={closeSnackbar} promise={downlink} snackbarKey={key}/>,{variant:"default", persist: true, key:key})
      }
    },
    name: instruction.name,
    icon: getIcon(instruction.icon),
  } as GenericActionBarAction;
}

export const getLoraNodeActionsFromHardWareType = (
  hardwareType: HardwareType,
  cachedHardwaretypes?: HardwareType[],
  enqueueSnackbar?: (message: SnackbarMessage, options: OptionsObject) => void,
  closeSnackbar?: (key?: SnackbarKey | undefined) => void,
  multicast = false,
) => {
  if (cachedHardwaretypes) {
    let index = cachedHardwaretypes.findIndex(
      (value) => value.id === hardwareType.id
    );

    if (index > 0) {
      const instructions = cachedHardwaretypes[index].instructions;
      if (instructions) {
        return instructions
          .filter((value) => !!value?.multicast == multicast)
          ?.map((instruction) =>
            mapHardwareInstructionToActionBarAction(
              instruction,
              useGetIconList().getIcon,
              enqueueSnackbar,
              closeSnackbar,
            )
          );
      }
    } else {
      const instructions = hardwareType.instructions;
      if (instructions) {
        return instructions
          .filter((value) => !!value?.multicast == multicast)
          .map((instruction) =>
            mapHardwareInstructionToActionBarAction(
              instruction,
              useGetIconList().getIcon,
              enqueueSnackbar,
              closeSnackbar,
            )
          );
      }
    }
  }

  const instructions = hardwareType.instructions;
  if (instructions) {
    return instructions.map((instruction) =>
      mapHardwareInstructionToActionBarAction(
        instruction,
        useGetIconList().getIcon
      )
    );
  }

  //In case of no instructions return empty array
  return [];
};

export const getMaintainLoraNodeActions = (
  dispatch: AppDispatch,
  setSelected: (node: LoraNode) => void,
  setOpen: (open: boolean) => void,
  hardwareType?: HardwareType,
  cachedHardwaretypes?: HardwareType[]
) => {
  let hardwareInstructions: GenericActionBarAction[] = [];

  if (hardwareType) {
    hardwareInstructions = getLoraNodeActionsFromHardWareType(
      hardwareType,
      cachedHardwaretypes
    );
  }

  return [
    {
      action: (state: LoraNode) => {
        dispatch(updateLoraNode(state));
        loraNodeCRUDServices.upsertItem(state);
      },
      name: "Save",
      icon: <Save />,
    },
    {
      action: (state: LoraNode) => {
        dispatch(updateLoraNode(state));
        loraNodeCRUDServices.deleteItem(state);
      },
      name: "Delete",
      icon: <DeleteIcon />,
    },
    {
      action: (state: LoraNode) => {
        setSelected(state);
        setOpen(true);
      },
      name: "Show Messages",
      icon: <MessageIcon />,
    },
    ...hardwareInstructions,
  ] as GenericActionBarAction[];
};
