import {LoraNode} from "../../../model/lora-node/LoraNode";
import {UiEnhancements} from "../../MaterialOnFire/custom-hooks/useGetRoutes";
import {GenericActionBarAction} from "../../MaterialOnFire/GenericUIFields/GenericActionBar";
import {Paper, Tooltip, Typography,} from "@mui/material";
import Grid from "@mui/material/Grid2";


import {getLoraNodeActionsFromHardWareType} from "./MaintainLoraNodeActions";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {HardwareType} from "../../../model/hardware-type/hardwareType";
import {NodeDetails} from "../../nodeDetails/NodeDetails";
import {useSnackbar} from "notistack";
import {useTranslation} from "../../MaterialOnFire/custom-hooks/useTranslation";
import {useGetNodeStatus} from "../useGetNodeStatus";
import {CreateLoraNodeWizardWrapper} from "./createNewLoraDeviceWizzard/CreateLoraNodeWizardWrapper";
import {getFunctions, httpsCallable} from "firebase/functions";
import {fireBaseApp} from "../../MaterialOnFire/firebase-config";
import {FeedbackSnackbar} from "../../FeedbackSnackbar";
import {useGetKapionBackendClient} from "../kapionBackendClients/useGetKapionBackendClient";
import "./loraNodesUIEnhancer.css"
import {MessageTimeline} from "../../nodeDetails/MessageTimeline";
import {OnboardingView} from "../../nodeDetails/OnboardingView";




export const useGetLoraNodesUIEnhancer = () => {
    const cachedHardwaretypes = useSelector(
        (
            state: RootState // @ts-ignore
        ) => state["hardwareTypes"]?.items as unknown as HardwareType[]
    );

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {t} = useTranslation();
    const {backendKPIClient} =  useGetKapionBackendClient()

    //Die GenericMaintenanceList führt das selected Item( in dem Fall die Lora Node)
    //im Redux State mit
    const selected = useSelector(
        (state: RootState) => state.maintenanceList.selected as LoraNode
    );

    const nodeStatus = useGetNodeStatus({id: selected?.id});
    const loraNodeActionBarEnhancer = (
        item: LoraNode,
        actions: GenericActionBarAction[]
    ) => {


        let saveAction = actions[0];
        let originalSaveAction = saveAction.action;

        saveAction.action = async ( newState, uiDefinition) => {
            await originalSaveAction( newState, uiDefinition);
            if (newState.hardwareType && newState.id){

                const key = Date.now();
                enqueueSnackbar(<FeedbackSnackbar  closeSnackbar={closeSnackbar} promise={ backendKPIClient.updateDeviceKPIIndex(newState.id,newState.hardwareType.id)}
                                                   componentIndicator={"upsertDeviceKPIIndex"}
                                                  snackbarKey={key}/>, {variant: "default", persist: true, key: key})

            }
        }

            // at position 1 there is the generated delete action
            let deleteAction = actions[1];
            deleteAction.action = () => {
                const functions = getFunctions(fireBaseApp, "europe-west1");
                const purgeDevice = httpsCallable(functions, "purgeDevice");

                const purgePromise = purgeDevice({
                    dev_eui: item.id,
                    applicationId: item.ttnPayload.ids.application_ids.application_id,
                    deviceId: item.ttnPayload.ids.device_id,
                })
                const key = Date.now();
                enqueueSnackbar(<FeedbackSnackbar closeSnackbar={closeSnackbar} promise={purgePromise}
                                                  snackbarKey={key}/>, {variant: "default", persist: true, key: key})
            }

            if (item?.hardwareType) {
                const hardwareInstructions = getLoraNodeActionsFromHardWareType(
                    item?.hardwareType,
                    cachedHardwaretypes,
                    enqueueSnackbar,
                    closeSnackbar,
                );
                return [...actions, ...hardwareInstructions];
            }
            return actions;
        };


    const loraNodeEnhancer : UiEnhancements = {
        enhanceActionBar: loraNodeActionBarEnhancer,
        newWizard: <CreateLoraNodeWizardWrapper/>,

        injectJSX: [
                    <Paper sx={{height: "100%", overflow:"hidden"}}>
                        <Grid container sx={{height: "100%", width: "100%"}} spacing={2}>
                            <Grid  size={{xs:8}} sx={{height: "2rem"}}>
                                <Typography variant={"h6"} align={"center"}>
                                    {t("NodeDetails.Measurements")}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                size={{xs:4}}
                                alignItems={"center"}
                                justifyContent={"left"}
                                sx={{height: "2rem"}}
                            >
                                <Tooltip
                                    title={
                                        <div>
                                            {t("NodeDetails.LastTransferred")}
                                            {(
                                                nodeStatus.timeSinceLastMessage /
                                                60 /
                                                60 /
                                                1000
                                            ).toFixed(2)}
                                            {t("Stunden")}
                                        </div>
                                    }
                                >
                                    <Typography variant={"h6"}>Status:</Typography>
                                </Tooltip>
                                {
                                    <div
                                        style={{
                                            backgroundColor: nodeStatus.color,
                                            width: "1.5rem",
                                            height: "1.5rem",
                                            borderRadius: "50%",
                                            marginLeft: "0.2rem"
                                        }}
                                        color={nodeStatus.color}
                                    ></div>
                                }
                            </Grid>
                            <Grid  size={{xs:12}} sx={{height: "100%"}} overflow={"hidden"}>
                                {<NodeDetails id={selected?.id} maxWidth={"500px"} maxHeight={"300px"}/>}
                            </Grid>
                        </Grid>
                    </Paper>
                ,

                    <Paper id="timeline-paper" sx={{height: "99.9%", overflow:"hidden", width: "100%"}}>
                        <Grid container sx={{height: "100%", width: "100%"}} spacing={2}>
                            <Grid  size={{xs:12}} sx={{height: "5%"}}>
                                <Typography variant={"h6"} align={"center"}>
                                    {t("NodeDetails.Messages")}
                                </Typography>
                            </Grid>

                            <MessageTimeline selectedNode={selected}/>
                        </Grid>
                    </Paper>

            ,

            <Paper id="timeline-paper" sx={{height: "99.9%", overflow:"hidden", width: "100%"}}>
                <Grid container sx={{height: "100%", width: "100%"}} spacing={2}>
                    <Grid  size={{xs:12}} sx={{height: "5%"}}>
                        <Typography variant={"h6"} align={"center"}>
                            {t("NodeDetails.Onboarding")}
                        </Typography>
                    </Grid>

                    <OnboardingView selectedNode={selected}/>
                </Grid>
            </Paper>

        ],
    } as UiEnhancements;

    return {key: "loraNodes", enhancer: loraNodeEnhancer};
};
