import {Gateway} from "../../../model/lora-node/gateway";
import {useEffect, useState} from "react";
import {useGetFirebaseConfigParameter} from "../../MaterialOnFire/custom-hooks/useGetFirebaseConfigParameter";
import {
    BACKEND_CONFIG_PARAMETER_NAME,
    backendRemoteConfig
} from "../../MaterialOnFire/custom-hooks/types/firebaseRemoteConfigTypes";
import {getEndpointUrlFromBackendConfig} from "../../custom-hooks/kapionBackendClients/useGetKapionBackendClient";
import {
    getFullUrlFromBackendConfig
} from "../../custom-hooks/uiEnhancers/Dashboards/DiagramHelper/getFullUrlFromBackendConfig";
import axios from "axios";
import {axiosHelper} from "../../custom-hooks/kapionBackendClients/axiosHelper";

export interface GatewayResponseItem {
    key: string;                // Der Schlüssel für den Eintrag, der eine Zeitstempel-ID und eine nodeId enthält
    nodeId: string;             // Die ID des Nodes
    time: string;               // Der Zeitstempel in ISO-8601 Format
    [key: string]: string|number;         // Die restlichen Felder sind die Werte der InfluxDB
}

interface QueryResponse {
    timeSeries: GatewayResponseItem[];
}
const GATEWAY_QUERY_START = "-36h"


export const useGetConnectedNodes = (gateway: Gateway | null) => {

    const [connectedNodesIds, setConnectedNodesIds] = useState<GatewayResponseItem[]>([])

    const backendConfig = useGetFirebaseConfigParameter<backendRemoteConfig>(BACKEND_CONFIG_PARAMETER_NAME)

    useEffect(() => {
            if (!gateway) {
                setConnectedNodesIds([])
                return
            }

            if (!backendConfig) {
                return
            }
            const callUrl = getFullUrlFromBackendConfig(backendConfig, "queryNodesConnectedToGateway")
            const axHelper = axiosHelper()
            axHelper.getAuthHeader().then((headers) => {
                axios.post(callUrl, { gatewayId: gateway.id,
                                           start: GATEWAY_QUERY_START,
                                           stop: "now()"
                }, {headers}).then((response) => {
                    setConnectedNodesIds((response.data as QueryResponse).timeSeries)
                })
            })
        }
        , [gateway, backendConfig]);

    return connectedNodesIds
}