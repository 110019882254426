import { initializeApp } from "firebase/app";

import { getRemoteConfig, fetchAndActivate, fetchConfig, activate } from "firebase/remote-config";
import {
  CACHE_SIZE_UNLIMITED,
  enableIndexedDbPersistence,
  initializeFirestore,
} from "firebase/firestore";
import {firebaseConfig} from "../../firebaseConfig";

export interface fireBaseAppInterface {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}
export let fireBaseApp: any = undefined;
export let remoteConfig: any= undefined;
let db: any = undefined;
export let firebaseDB = db;
let remoteConfigInitialized = false;

export const getRemoteConfigInstance = async () => {
  if(remoteConfigInitialized ) {
    return remoteConfig
  }

  if(!fireBaseApp) {
    throw new Error("Remote Config not initialized. Reason: Firebase App not initialized")
  }

  let remoteConfigLcl = getRemoteConfig(fireBaseApp);

  remoteConfigLcl.defaultConfig ={
    "kapionBackendConfig": "https://kapion-backend.herokuapp.com",
    "test": "test123456"

  }

  remoteConfigLcl.settings.minimumFetchIntervalMillis = 60000; //Das muss fürs Testen runtergesetzt werden :)

   await fetchConfig(remoteConfigLcl)
   await activate(remoteConfigLcl)
   remoteConfigInitialized = true
  remoteConfig = remoteConfigLcl

  return remoteConfigLcl


}

export const initFireBaseApp = (firebaseConfig: fireBaseAppInterface) => {
  if (fireBaseApp) {
  } else {
    fireBaseApp = initializeApp(firebaseConfig);



    db = initializeFirestore(fireBaseApp, {
      cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    });
    firebaseDB = db;
    enableIndexedDbPersistence(db).catch((err) => {
      if (err.code == "failed-precondition") {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code == "unimplemented") {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });
  }
};
