import { TCreateLoraNodeDataModel } from "../CreateLoraNodeWizardWrapper";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useSelector } from "react-redux";
import { useTranslation } from "../../../../MaterialOnFire/custom-hooks/useTranslation";
import { SunWatch } from "../../SunWatch/SunWatch";
import { useCallback, useState } from "react";
import {
  dimmingProfile,
  getSunriseSunset,
} from "../../SunWatch/TimeOperationHelper";

export const ChooseEndpointStep = (props: {
  newLoraNode: TCreateLoraNodeDataModel;
  updateNewLoraNode: (newState: TCreateLoraNodeDataModel) => void;
}) => {
  const endpoints = useSelector(
    (state: any) => state["TTSSecrets"]?.items as unknown as any[]
  );
  const { t } = useTranslation();

  const sunriseSunset = getSunriseSunset(52.52, 13.405, new Date());
  const [dimmingProfiles, setDimmingProfiles] = useState<dimmingProfile[]>([]);

  const updateDimmingProfiles = useCallback(
    (dimmingProfiles: dimmingProfile[]) => {
      setDimmingProfiles(dimmingProfiles);
    },
    [setDimmingProfiles]
  );

  return (
    <Grid
      style={{
        height: "100%",
        width: "100%",
      }}
      container
    >
      <Grid  size={{xs:6}}>
        <FormControl fullWidth required={true}>
          <InputLabel>
            {t("createLoraNodeWizard.chooseEndpointStep.selectEndpoint")}
          </InputLabel>
          <Select
            value={props.newLoraNode?.applicationId || ""}
            label={t("createLoraNodeWizard.chooseEndpointStep.selectEndpoint")}
          >
            {endpoints.map((endpoint) => (
              <MenuItem
                  key={`endpointMenuItem-${endpoint.id}`}
                value={endpoint.id}
                onClick={() =>
                  props.updateNewLoraNode({
                    ...props.newLoraNode,
                    applicationId: endpoint.id,
                  })
                }
              >
                {endpoint.id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
