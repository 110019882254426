import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { GenericFieldInterface } from "./GenericFieldInterface";
import * as React from "react";
import { setHasError } from "../GenericUIViews/GenericMaintenanceList/GenericMaintenanceListState";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { useTranslation } from "../custom-hooks/useTranslation";
import {KapionTextEditField} from "./BaseComponents/KapionTextEditField";

interface GenericTextEditFieldInterface extends GenericFieldInterface {
  type: React.InputHTMLAttributes<unknown>["type"];
  multiline? : boolean;
}

export const GenericTextEditField = (props: GenericTextEditFieldInterface) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(true);
  const [localValue, setLocalValue] = useState<any>(undefined);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    setLocalValue(props.value || "");
  }, [props.value]);

  useEffect(() => {
    if (props.uiDescriptor.validator) {
      const regex = new RegExp(props.uiDescriptor.validator);
      if (props.value) {
        const testResult = regex.test(props.value);
        dispatch(setHasError(!testResult));
        setIsValid(testResult);
      }
    }
  }, [props.value, props.uiDescriptor.validator]);

  return (
    <Grid key={props.uiDescriptor.fieldName} size={{ xs: props.uiDescriptor.size || 4}}>
        <KapionTextEditField key={props.uiDescriptor.fieldName} t={t(`${props.translationPrefix}.${props.fieldKey}`)}
                             required={props.required} id={props.fieldKey} value={props.value} type={props.type}
                             multiline={props.multiline}
                             disabled={props.disabled} valid={isValid} onChange={(e) => {
            setLocalValue(e.currentTarget.value);
            props.onChange && props.onChange(e)
        }}/>
    </Grid>
  );
};
