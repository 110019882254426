import {useGetFirebaseConfigParameter} from "../../MaterialOnFire/custom-hooks/useGetFirebaseConfigParameter";
import {
    BACKEND_CONFIG_PARAMETER_NAME,
    backendRemoteConfig
} from "../../MaterialOnFire/custom-hooks/types/firebaseRemoteConfigTypes";
import {useMemo} from "react";
import {getEndpointUrlFromBackendConfig} from "./useGetKapionBackendClient";
import {axiosHelper} from "./axiosHelper";
import axios from "axios";
import {TOnboardingTemplate} from "../uiEnhancers/HardwareTypeUI/OnboardingTemplateTypes";
import {HardwareType} from "../../../model/hardware-type/hardwareType";
import {OnboardingDocument} from "../../nodeDetails/OnboardingView";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";

interface KapionBackendOnboardingClient {
    createOnboardingDocument: ( applicationId: string, deviceId : string,dev_eui: string, hardwareTypeId : string) => Promise<any>,
    retrieveOnboardingDocument: (dev_eui: string) => Promise<OnboardingDocument>,
    deleteOnboardingDocument: (dev_eui: string) => Promise<void>,
}
//TODO fix with felix why is this called twice
export const useGetKapionBackendOnboardingClient = () =>{
    const cachedHardwaretypes = useSelector(
        (
            state: RootState // @ts-ignore
        ) => state["hardwareTypes"]?.items as unknown as HardwareType[]
    );


    // Function to get HardwareType by id (with for loop for better compatibility)
    const getHardwareTypeById = (id: string): HardwareType | undefined => {
        for (let i = 0; i < cachedHardwaretypes.length; i++) {
            if (cachedHardwaretypes[i].id === id) {
                return cachedHardwaretypes[i];
            }
        }
        return undefined; // Return undefined if no match is found
    };

    const backendConfig = useGetFirebaseConfigParameter<backendRemoteConfig>(BACKEND_CONFIG_PARAMETER_NAME)
    const endpointUrl = useMemo(() => { return backendConfig? getEndpointUrlFromBackendConfig(backendConfig, "ONBOARDING"):"" }, [backendConfig])
    const axHelper = axiosHelper()

    const client = useMemo<KapionBackendOnboardingClient | undefined>(() => {
        if (!endpointUrl){
            return;
        }

        return{
            createOnboardingDocument: async (applicationId,deviceId, dev_eui, hardwareTypeId) => {
                const hardwareType = getHardwareTypeById(hardwareTypeId);
                if (!hardwareType) {
                    throw new Error("Hardwaretype does not exist");
                }
                const headers = await axHelper.getAuthHeader()
                const response = await axios.post(`${endpointUrl}/createDocument`, {deviceId, dev_eui,hardwareTypeId: hardwareType.id, hardwareType: hardwareType.name, applicationId, instructions: hardwareType.onboardingTemplate}, { headers : headers} )
                return response.data;
            },
            retrieveOnboardingDocument: async (dev_eui) => {
                const headers = await axHelper.getAuthHeader()
                const response = await axios.get(`${endpointUrl}/retrieveDocument/${dev_eui}`,  { headers : headers} )
                return response.data;
            },
            deleteOnboardingDocument: async (dev_eui: string) => {
                const headers = await axHelper.getAuthHeader()
                const response = await axios.delete(`${endpointUrl}/deleteDocument/${dev_eui}`, { headers : headers} )
                return response.data;
            },


        }as KapionBackendOnboardingClient

    },[endpointUrl,backendConfig])

    return client

}