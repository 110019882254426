import {
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Map from "./Map";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSearchEnabled } from "../MaterialOnFire/app-bar/appBarState";
import { NodeOverview } from "./overview/NodeOverview";

export default () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSearchEnabled(true));
  }, []);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container sx={{ height: "100%", width: "100%" }} overflow={"hidden"}>
      <Grid size={{ xs: 12, md: 8}}  height={"100%"}>
        <Map />
      </Grid>
      {smallScreen ? (
        <></>
      ) : (
        <Grid  size="grow" component={Paper} sx={{ mx: 1 }} height={"100%"} overflow={"auto"}>
          <NodeOverview />
        </Grid>
      )}
    </Grid>
  );
};
